.filterSelect {
    width: 30rem;
    position: relative;
    cursor: pointer;
}

.filterIcon {
    position: absolute;
    left: 1rem;
    top: 50%;
    transform: translateY(-50%);
    width: 1.6rem;
    z-index: 1;
}

.select {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;
    border-radius: 0.4rem;
    position: relative;
    background-color: #fff;
    border: 1px solid var(--grey2);
    font-size: 1.6rem;
    padding: 0.8rem 0.8rem 0.8rem 3.2rem;
    font-family: var(--segoe);
    min-height: 4rem;
}

.select.active {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}

.placeholder {
    font-size: 1.4rem;
    line-height: 1.7rem;
}

.icon {
    position: absolute;
    right: 1rem !important;
    top: 50% !important;
    transform: translateY(-50%) !important;
}

.dropdown {
    position: absolute;
    transform: translateY(100%);
    bottom: 1px;
    background-color: #fff;
    border: 1px solid var(--grey2);
    width: 100%;
    z-index: 9;
    padding: 1rem 0;
}

.dropdown li {
    padding: 1rem 1.6rem;
    font-size: 1.4rem;
    line-height: 1.7rem;
}

.dropdown li:hover {
    cursor: pointer;
}

.dropdown li:hover,
.dropdown li.active {
    background-color: var(--grey3);
    font-family: var(--segoe-semibold);
}

@media (max-width: 599px) {
    .filterSelect {
        width: 100%;
    }
}
