.SubscriptionPaymentModal {
    outline: none;
}

.summary {
    width: 100%;
    padding: 3rem;
    background-color: var(--green3);
    border: 1px solid #929ea7;
    border-radius: 1.2rem;
    margin-bottom: 3rem;
}

.summary p {
    font-size: 1.6rem;
    font-family: var(--roboto);
    margin-bottom: 1.7rem;
    color: var(--darkBlue);
}

.summary p:last-child {
    margin-bottom: 0;
}

.summary .green {
    font-size: 1.6rem;
    font-family: var(--roboto);
    color: var(--green);
    font-weight: 700;
}

.summary .grey {
    font-size: 1.4rem;
    font-family: var(--roboto);
    color: var(--grey);
}

.SubscriptionPaymentModalInner {
    padding: 2rem 3rem 2rem;
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    outline: none;
}

.modalBody {
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    width: 70%;
    margin: 0 auto;
}

.headerRow {
    display: flex;
    flex-grow: 1;
    width: 100%;
    align-items: center;
}

.header {
    font-size: 3rem;
    font-family: var(--segoe);
    font-family: 700;
    color: #7e9591;
    text-transform: uppercase;
    margin: 0 2rem;
    font-weight: 700;
    text-align: center;
}

.subheader {
    font-size: 2.2rem;
    font-family: var(--robot);
    font-family: 700;
    color: var(--green);
    margin: 3rem 2rem -2rem;
    font-weight: 700;
    text-align: center;
}

.infoText {
    color: #263d50;
    font-size: 1.6rem;
    font-family: var(--roboto);
    text-align: center;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    line-height: 2rem;
    margin-top: 5rem;
    margin-bottom: 5rem;
}

.counter {
    font-size: 1.8rem;
    font-family: var(--robot);
    font-weight: 700;
    text-align: center;
    margin-top: 3rem;
    margin-bottom: 2rem;
}

.line {
    flex-grow: 1;
    height: 1px;
    background-color: #7e9591;
}

.inputContainer {
    width: 100%;
}

.input {
    width: 100%;
    border-radius: 4px;
}

.label {
    font-size: 1.4rem;
    font-family: var(--segoe);
    color: #7e9591;
    text-transform: uppercase;
    margin-bottom: 1rem;
}

.button {
    width: 35rem;
    margin: 3rem auto;
}

.inverseButton {
    background: #fff;
    color: #263d50;
    border-color: #263d50;
}

.inverseButton:hover {
    background: #fff;
}

.smaller {
    margin-top: 4rem;
    font-weight: 400;
}

.smaller .header {
    font-size: 1.6rem;
}

.link {
    font-family: var(--segoe);
    text-align: center;
    font-weight: 700;
    color: #11a2ed;
    font-size: 1.6rem;
    text-decoration: none;
}

.backLink {
    font-family: var(--segoe);
    text-align: center;
    font-weight: 700;
    color: #11a2ed;
    font-size: 1.6rem;
    padding: 0.5rem 1rem;
}

.link:hover,
.backLink:hover {
    cursor: pointer;
}

.paymentImage {
    height: 5rem;
    margin-right: 3rem;
    margin-bottom: 2rem;
    margin-top: 2rem;
}

.paymentInfo {
    font-size: 1.6rem;
    font-family: var(--segoe);
    font-style: italic;
    margin-bottom: 2rem;
    color: #263d50;
}

.link:hover {
    cursor: pointer;
}

.note {
    font-family: var(--roboto);
    color: var(--grey);
    font-size: 1.4rem;
    margin-top: 3rem;
}

@media (max-width: 959px) {
    .SubscriptionPaymentModal {
        width: 40rem;
    }

    .SubscriptionPaymentModalInner {
        padding: 1rem;
    }

    .header {
        font-size: 2rem;
    }
}

@media (max-width: 550px) {
    .SubscriptionPaymentModal {
        width: auto;
    }
}
