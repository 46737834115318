.subscription {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    background-color: var(--green4);
    border: 1px solid var(--green1);
    border-radius: 0.6rem;
    padding: 2rem;
    min-height: 22rem;
    height: 100%;
}

.subscription .extraTextContainer {
    position: absolute;
    font-size: 1.4rem;
    font-family: var(--segoe-semibold);
    color: #fff;
    padding: 0.5rem 1rem;
    border-top-left-radius: 0.6rem;
    border-top-right-radius: 0.6rem;
    transform: translateY(-100%);
    top: 0;
}

.subscription.trial {
    border: 1px solid var(--green);
}

.subscription.recommended {
    border: 1px solid var(--darkBlue);
}

.subscription .extraTextContainer.trial {
    background-color: var(--green);
    border: 1px solid var(--green);
}

.subscription .extraTextContainer.recommended {
    background-color: var(--darkBlue);
    border: 1px solid var(--darkBlue);
}

.subscription header {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 2rem;
}

.subscription .icon {
    width: 1.8rem;
    margin-right: 1.4rem;
}

.subscription h3 {
    font-family: var(--segoe-semibold);
    font-size: 1.8rem;
    line-height: 2.4rem;
    color: var(--darkBlue);
    text-transform: unset;
}

.subscription p {
    font-size: 1.4rem;
    font-family: var(--segoe);
    line-height: 1.9rem;
    color: var(--darkBlue);
}

.subscription footer {
    display: flex;
    flex-direction: column;
    margin-top: auto;
}

.subscription footer .trialText {
    width: 100%;
    flex-basis: 100%;
    font-size: 1.2rem;
}

.subscription .footerContent {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
}

.subscription footer .price {
    font-size: 1.8rem;
    margin-right: auto;
    line-height: 2.4rem;
    font-family: var(--segoe-semibold);
    color: var(--darkBlue);
}

.subscription footer .link {
    font-size: 1.5rem;
    font-family: var(--segoe-semibold);
    color: var(--linkBlue);
    line-height: 1;
    cursor: pointer;
}

.subscription .button {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 4rem;
    line-height: 4rem;
    background-color: var(--green);
    color: #fff;
    font-family: var(--segoe-semibold);
    margin: 2rem auto 0;
}

.subscription .details {
    display: flex;
    flex-direction: column;
    padding: 2rem 0;
}

.subscription .details li {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    border-bottom: 1px solid var(--grey2);
    padding: 0.8rem 0;
}

.subscription .details li:last-child {
    border-bottom: unset;
}

.subscription .details li img {
    width: 1.5rem;
    margin-right: 1rem;
}

@media (max-width: 599px) {
    .subscription {
        min-height: 20rem;
        padding: 1.8rem;
    }
}
