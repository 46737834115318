.list li {
    display: flex;
    /* padding-bottom: 1rem;
  padding-top: 1rem; */
    /* border-bottom: 1px solid #eee; */
    justify-content: space-between;
}

.list li span {
    font-size: 1.4rem;
    font-family: var(--segoe);
    color: var(--darkBlue);
    line-height: 3rem;
}

.highlight {
    color: white;
    font-size: 1.4rem;
    background-color: var(--darkBlue);
    font-family: var(--segoe);
    line-height: 3rem;
    padding: 0 1rem;
    border-radius: 2rem;
    font-weight: 700;
}

.left {
    font-weight: 300;
}

.right {
    font-weight: 700;
    text-align: right;
}

.discounted {
    color: red !important;
}

@media (max-width: 959px) {
}
