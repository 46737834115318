.FooterPaymentElement {
    margin-right: 2.6rem;
    font-size: 1.8rem;
}

@media (max-width: 599px) {
    .FooterPaymentElement {
        margin-right: 0;
        font-size: 1.4rem;
    }
}
