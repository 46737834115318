.item {
    margin-bottom: 2rem;
}

.item label {
    display: block;
    margin-bottom: 1rem;
    font-size: 1.4rem;
    font-family: var(--segoe-semibold);
    color: var(--darkBlue);
}

.itemRow {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 2rem;
}

.revoke {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    gap: 0.5rem;
    color: var(--linkBlue) !important;
    font-family: var(--segoe-semibold);
    font-size: 1.6rem;
}

@media (max-width: 599px) {
    .itemRow {
        gap: 1rem;
    }

    .revoke span {
        display: none;
    }
}
