.inputWrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 3.8rem;
    max-width: 100%;
    background-color: #fff;
    border: 1px solid var(--grey2);
    border-radius: 0.4rem;
}

.inputWrapper div {
    font-size: 1.4rem;
    color: var(--grey);
    line-height: 1;
    padding: 0 0 0 1rem;
}

.inputWrapper div:last-child {
    padding: 0 1rem 0 0;
}

.input {
    flex: 1;
    height: auto;
    font-family: var(--segoe);
    font-size: 1.4rem;
    color: var(--darkBlue);
    background-color: unset;
    border: unset;
    border-radius: unset;
    padding: 0 1rem;
    position: relative;
    top: -1px;
}

.input.bigger {
    height: 5rem;
    border-radius: 6px;
    padding: 1.4rem 1.8rem;
    font-size: 1.6rem;
}
