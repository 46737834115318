.VerifyEmail {
    min-height: calc(100vh - 306px);
}

.VerifyEmailInner {
    padding-top: 5rem;
}

.VerifyEmailInner p,
.VerifyEmailInner h3 {
    color: #263d50;
    font-family: var(--segoe);
}

.VerifyEmail h3 {
    font-size: 3rem;
    margin-bottom: 4rem;
}

.VerifyEmail p {
    font-size: 2rem;
    margin-bottom: 1.5rem;
}
