.title {
    font-family: var(--segoe);
    color: var(--darkBlue);
    font-size: 1.6rem;
    font-weight: bold;
    margin-top: 2rem;
    margin-bottom: 2rem;
}

.cardText {
    font-size: 1.6rem;
    font-family: var(--roboto);
    font-weight: normal;
    color: #263d50;
    margin-bottom: 1rem;
}

.text {
    font-size: 1.6rem;
    font-family: var(--segoe);
    color: #11a2ed;
    font-weight: bold;
    cursor: pointer;
    margin-top: 2rem;
    margin-bottom: 2rem;
}

.innerContainer {
    margin-bottom: 2rem;
    /* display: flex; */
    /* justify-content: space-between;
  align-items: flex-start; */
}

.titleContainer {
    display: flex;
    align-items: center;
}

.data {
    font-size: 1.6rem;
    color: #263d50;
    font-family: var(--segoe);
}

@media (max-width: 599px) {
    .innerContainer {
        margin-bottom: 1rem;
    }
}
