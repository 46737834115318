.service {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    background-color: var(--green4);
    border: 1px solid var(--green1);
    border-radius: 0.6rem;
    padding: 2rem;
    min-height: 22rem;
}

.service header {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 2rem;
}

.service .icon {
    width: 1.8rem;
    margin-right: 1.4rem;
}

.service h3 {
    font-family: var(--segoe-semibold);
    font-size: 1.8rem;
    line-height: 2.4rem;
    color: var(--darkBlue);
    text-transform: unset;
}

.service p {
    font-size: 1.4rem;
    font-family: var(--segoe);
    line-height: 1.9rem;
    color: var(--darkBlue);
}

.service footer {
    display: flex;
    justify-content: flex-end;
    align-items: baseline;
    margin-top: auto;
}

.service footer .price {
    font-size: 1.4rem;
    margin-right: auto;
    line-height: 1;
}

.service footer .link {
    font-size: 1.5rem;
    font-family: var(--segoe-semibold);
    color: var(--linkBlue);
    line-height: 1;
}

.service .button {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 4rem;
    line-height: 4rem;
    background-color: var(--green);
    color: #fff;
    font-family: var(--segoe-semibold);
    margin: 2rem auto 0;
}

.service .details {
    display: flex;
    flex-direction: column;
    padding: 2rem 0;
}

.service .details li {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    border-bottom: 1px solid var(--grey2);
    padding: 0.8rem 0;
}

.service .details li:last-child {
    border-bottom: unset;
}

.service .details li img {
    width: 1.5rem;
    margin-right: 1rem;
}

.service .details li p {
}

@media (max-width: 599px) {
    .service {
        min-height: 20rem;
        padding: 1.8rem;
    }
}
