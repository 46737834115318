.title {
    font-size: 2rem;
    font-family: var(--roboto);
    font-weight: 700;
    color: var(--darkBlue);
}

.content p,
.content span {
    font-size: 1.6rem;
    font-family: var(--roboto);
    line-height: 2.2rem;
    color: var(--darkBlue);
}

.buttonText {
    font-size: 1.4rem;
    font-weight: 700;
    color: var(--green);
}

.humed.buttonText {
    color: var(--humedDark);
}
