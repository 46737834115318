.list {
    margin: 2rem 0;
}

.list li {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid var(--green1);
    padding: 1rem 0;
}

.list li span {
    font-size: 1.4rem;
    font-family: var(--segoe);
    color: var(--darkBlue);
}

.highlight {
    color: var(--darkBlue);
    font-size: 1.4rem;
    font-family: var(--segoe-semibold) !important;
}

.left {
    font-family: var(--segoe);
    line-height: 1.3;
}

.right {
    font-family: var(--segoe);
    text-align: right;
    line-height: 1.3;
}

.discounted {
    color: red !important;
}

@media (max-width: 959px) {
}
