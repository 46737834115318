/* Outside white border */
.assetMapImageMarker {
    /* background-color: #1FBA9C;  */
    border-radius: 0.5rem;
    cursor: pointer !important;
    height: 4rem;
    width: 4rem;
    margin-left: -2rem; /* margin-left = -width/2  */
    margin-top: -5rem; /*  margin-top = -height + arrow  */
    padding: 0px;
    position: absolute;
}

/* Arrow on bottom of container */
/* .assetMapImageMarker:after {
	border-color: #1FBA9C transparent;
	border-style: solid;
	border-width: 1rem 1rem 0;
	bottom: -1rem;
	content: '';
	display: block;
	left: 1rem;
	position: absolute;
	width: 0;
} */

.arrow {
    /* border-color: #1FBA9C transparent;  */
    border-style: solid;
    border-width: 1rem 1rem 0;
    bottom: -1rem;
    content: "";
    display: block;
    left: 1rem;
    position: absolute;
    width: 0;
    margin-left: -2rem; /* margin-left = -width/2  */
    margin-top: -5rem; /*  margin-top = -height + arrow  */
    margin-bottom: 1rem;
}

/* Inner image container */
.assetMapImageMarker div {
    background-color: #1fba9c;
    background-position: center center;
    background-size: cover;
    border-radius: 0.5rem;
    height: 3.6rem;
    margin: 0.2rem;
    width: 3.6rem;
}
