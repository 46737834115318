.securitySettings {
    margin-bottom: 3rem;
}

.securitySettings p {
    font-family: var(--segoe);
    color: var(--darkBlue);
}

.PageSubHeader {
    margin-bottom: 2rem;
}

.modifiedAt {
    margin-bottom: 2rem;
}

.label {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 1.4rem !important;
    line-height: 1.9rem !important;
    color: var(--darkBlue) !important;
    font-family: var(--segoe-semibold) !important;
    margin-bottom: 0.5rem !important;
}

.label svg {
    fill: var(--linkBlue);
}

.formControl {
    width: 100%;
}

.button {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 4rem;
    line-height: 4rem;
    background-color: var(--green);
    color: #fff;
    font-family: var(--segoe-semibold);
    margin: unset;
}
