.tag {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    padding: 0.5rem 1rem;
    max-height: 2.8rem;
    background-color: var(--green);
    font-family: var(--segoe);
    color: #fff;
    font-size: 1.4rem;
    line-height: 1;
    border-radius: 0.3rem;
}

.tag::first-letter {
    text-transform: uppercase;
}

.outlined {
    background-color: #fff;
    border: 1px solid var(--grey2);
    color: var(--darkBlue);
}

.outlined {
    background-color: #fff;
    border: 1px solid var(--grey2);
    color: var(--darkBlue);
}
