.scope {
    color: var(--darkBlue);
    background-color: var(--grey3);
    padding-top: 3rem;
    padding-bottom: 3.6rem;
}

.scope [class*="PaperSection_section"] {
    border: unset;
    min-height: 100vh;
}

.scope h2 {
    font: 600 1em var(--segoe);
}

.scope p {
    margin-top: 1.43em;
    font-size: 0.875em;
}

.scope label {
    display: block;
    margin-top: 3em;
    font-size: 0.875em;
    font-weight: 600;
    line-height: 2;
}

.button {
    margin: 5rem 0 0;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 4rem;
    line-height: 4rem;
    background-color: var(--green);
    color: #fff;
    font-family: var(--segoe-semibold);
    font-size: 1.4rem;
}
