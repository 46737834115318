.modalBody {
    padding: 0 !important;
}

.subscriptionsContainer {
    padding: 0 4rem;
    position: relative;
}

.subscriptionsContainer.closed {
    height: 40rem;
    overflow: hidden;
}

.overlay {
    position: absolute;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    top: 0;
    left: 0;
    height: 40rem;
    width: 100%;
    background: rgb(255, 255, 255);
    background: linear-gradient(
        180deg,
        rgba(255, 255, 255, 0.6320903361344538) 0%,
        rgba(31, 186, 156, 1) 100%
    );
}

.overlay p {
    font-size: 4.2rem;
    font-family: var(--roboto);
    font-weight: 700;
    color: #fff;
    margin-bottom: 3rem;

    text-transform: uppercase;
}

.hideSubscriptions {
    font-size: 1.4rem;
    text-align: center;
    font-family: var(--roboto);
    color: var(--green);
    margin-top: 2rem;
    margin-bottom: 2rem;
}

.hideSubscriptions:hover,
.overlay p:hover {
    cursor: pointer;
    text-decoration: underline;
}

.list {
    margin-bottom: 3rem;
}

.list li {
    display: flex;
    padding-bottom: 1rem;
    padding-top: 1rem;
    border-bottom: 1px solid #eee;
    justify-content: space-between;
}

.list li span {
    font-size: 1.6rem;
    font-family: var(--segoe);
    color: var(--darkBlue);
    line-height: 3rem;
}

.left {
    font-weight: 300;
}

.right {
    font-weight: 700;
    text-align: right;
}

.subscriptions {
    margin-bottom: 2rem;
}

.inputContainer .label {
    font-size: 1.4rem;
    font-family: var(--segoe);
    color: #7e9591;
    text-transform: uppercase;
    margin-bottom: 1rem;
}

.redeemButton {
    margin-top: 2rem;
    margin-bottom: 4rem;
}

.summary {
    background-color: var(--green);
    padding: 4rem 4rem 5rem;
    text-align: center;
}

.summary p {
    font-size: 2.4rem;
    font-family: var(--roboto);
    font-weight: 700;
    margin-bottom: 1rem;
    color: #fff;
}

.summary .infoText {
    font-size: 1.4rem;
    font-style: italic;
}

.summary .price {
    font-size: 3.4rem;
}

.confirmButton {
    margin-top: 2rem;
}

.summary .link {
    margin-top: 3rem;
    font-size: 1.6rem;
    font-family: var(--segoe-bold);
}

.summary .link:hover {
    text-decoration: underline;
}

.payment {
    background-color: #f8f8f8;
    padding-top: 3rem;
    padding-bottom: 2rem;
}

.payment .inner {
    padding: 0 30rem;
}

.itemContainer {
    font-size: 2rem;
    color: var(--darkBlue);
    display: flex;
    align-items: center;
    margin-bottom: 3rem;
}

.quantity {
    font-family: var(--segoe);
    margin-right: 3rem;
}

.itemName {
    font-family: var(--segoe-bold);
}

.itemInfo {
    font-family: var(--segoe);
    font-size: 1.6rem;
}

.line {
    margin-top: 5rem;
    margin-bottom: 5rem;
}

.summary .subscriptionInitText {
    width: 60%;
    margin: 0 auto;
    line-height: normal;
    font-size: 1.6rem;
}

.accordion {
    /* margin-bottom: 2rem; */
}

.accordionInner {
    font-size: 1.6rem;
    font-family: var(--segoe-bold);
    text-transform: uppercase;
    background-color: transparent;
}

.accordionDetailsInner {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

@media (max-width: 959px) {
    .payment .inner {
        padding: 0 6rem;
    }

    .summary .subscriptionInitText {
        width: 100%;
    }

    .accordionDetailsInner {
        flex-direction: column;
    }

    .subscriptionsContainer {
        padding: 0 1rem;
    }

    .subscriptionsContainer.closed {
        height: 30rem;
    }

    .overlay {
        height: 30rem;
    }

    .overlay p {
        font-size: 3rem;
        text-align: center;
    }
}

@media (max-width: 599px) {
    .payment .inner {
        padding: 0 3rem;
    }

    .quantity {
        margin-right: 1rem;
        width: 20%;
    }

    .item {
        width: 80%;
    }

    .itemName {
        display: block;
        margin-bottom: 1rem;
    }
}
