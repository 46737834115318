.link {
    color: var(--linkBlue);
    text-decoration: none;
    font-weight: 700;
}

input,
textarea {
    width: 100%;
    background-color: var(--green3);
    border: 1px solid var(--grey);
    border-radius: 6px;
    padding-left: 14px;
    color: var(--grey);
    font-family: inherit;
    font-size: 16px;
}

input {
    height: 54px;
}

textarea {
    height: 158px;
    resize: none;
}

input:focus,
textarea:focus {
    outline: none;
}
