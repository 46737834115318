.SubscriptionSelect {
    background-color: #f8f8f8;
    border-radius: 6px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    padding: 1.3rem 1rem;
    height: 9.5rem;
    width: 24.5rem;
    border: 2px solid transparent;
}

.SubscriptionSelectOuter {
    margin-bottom: 2rem;
}

.SubscriptionSelect.hover:hover {
    border-color: var(--green);
    cursor: pointer;
}

.SubscriptionSelect.selected {
    border-color: var(--green);
    justify-content: flex-start;
}

.datas {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
}

.datas * {
    text-align: center;
}

.icon {
    color: var(--green);
    width: 3rem !important;
    height: 3rem !important;
}

.name,
.price {
    font-size: 1.6rem;
    font-family: var(--segoe);
    color: var(--darkBlue);
}

.price {
    font-size: 1.6rem;
    font-weight: 700;
    /* width: 100%; */
    /* flex-grow: 2; */
}

.subtext {
    font-size: 1.4rem;
    font-family: var(--segoe);
    color: #929ea7;
}

.cancelChange {
    color: var(--red);
    font-family: var(--roboto);
    font-size: 1.2rem;
    text-align: center;
    margin-top: 0.5rem;
}

.cancelChange:hover {
    text-decoration: underline;
    cursor: pointer;
}

.discounted .price {
    color: red;
}

@media (max-width: 1250px) {
    .SubscriptionSelect {
        width: 19rem;
    }

    .icon {
        width: 2.5rem !important;
        height: 2.5rem !important;
    }
}

@media (max-width: 959px) {
    .SubscriptionSelect {
        height: 12rem;
    }

    .datas {
        justify-content: center;
    }

    .name {
        margin-bottom: 1rem;
        margin-top: 1rem;
    }
}

@media (max-width: 599px) {
    .SubscriptionSelectOuter {
        width: 48%;
    }

    .SubscriptionSelect {
        width: 100%;
        flex-direction: column;
        height: 16rem;
        justify-content: center;
    }

    .name {
        margin-bottom: 2rem;
        margin-top: 1rem;
    }

    .name {
        margin-bottom: 2rem;
        margin-top: 1rem;
    }
}
