.card {
    padding: 4rem 2rem 3rem;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
    border: 2px solid transparent;
}

.contentContainer {
    margin-top: 1.5rem;
}

.yellowTextCard {
    border: 2px solid var(--yellow);
}

.greenTextCard {
    border: 2px solid var(--green);
}

.name {
    color: var(--darkBlue);
    font-size: 2.6rem;
    font-family: var(--segoe-bold);
    text-align: center;
    text-transform: uppercase;
    margin-bottom: 2rem;
}

.description {
    color: var(--darkBlue);
    font-size: 1.6rem;
    font-family: var(--roboto);
    font-weight: 300;
    text-align: center;
    line-height: normal;
    min-height: 11rem;
}

.toggle {
    color: var(--linkBlue);
    font-size: 1.6rem;
    font-family: var(--segoe-bold);
    text-align: center;
    /* margin-top: 2rem; */
    margin-bottom: 2rem;
}

.toggle:hover {
    cursor: pointer;
}

.price {
    font-size: 3.6rem;
    font-family: var(--segoe);
    color: var(--darkBlue);
    text-align: center;
    margin-bottom: 4rem;
}

.trialText {
    font-size: 1.8rem;
    font-family: var(--segoe);
    text-align: center;
}

.trialPrice {
    font-size: 2.6rem;
    font-family: var(--segoe);
    text-align: center;
    margin-bottom: 3rem;
}

.extraTextContainer {
    text-align: center;
    position: absolute;
    top: -1rem;
    right: -2px;
    /* width: 100%; */
}

.yellowText {
    font-size: 1.6rem;
    font-family: var(--roboto);
    color: var(--darkBlue);
    padding: 0.5rem 0.7rem;
    text-transform: uppercase;
    font-weight: 700;
    background-color: var(--yellow);
    position: relative;
}

.greenText {
    font-size: 1.6rem;
    font-family: var(--roboto);
    color: #fff;
    padding: 0.5rem 0.7rem;
    text-transform: uppercase;
    font-weight: 700;
    background-color: var(--green);
    position: relative;
}

.bottomTriangle {
    width: 1px;
    height: 1px;
    border: 1.5rem solid transparent;
    transform: rotateZ(45deg);
    position: absolute;
    right: 6px;
    top: 48%;
}

.yellowText .bottomTriangle {
    border-right-color: #e9c944;
}

.greenText .bottomTriangle {
    border-right-color: #20ad94;
}

@media (max-width: 959px) {
    .card {
        padding: 3rem 2rem;
    }

    .name {
        margin-bottom: 2;
    }

    .nameRow {
        display: flex;
        flex-direction: column;
        /* justify-content: space-between;
      align-items: center; */
        margin-bottom: 2rem;
    }

    /* .priceContainer {
      display: flex;
      flex-direction: column;
      justify-content: center;
  } */
    .price {
        margin-bottom: 0;
    }

    .yellowTextCard,
    .greenTextCard {
        border: none;
    }

    .description {
        font-size: 1.4rem;
        min-height: auto;
        text-align: left;
        margin-bottom: 2rem;
    }

    .buttonAndDetails {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 2rem;
    }

    .buttonAndDetails .button {
        align-self: center;
        /* width: 20rem; */
        margin: 0;
        line-height: 4rem;
    }

    /* .toggle {
      margin-bottom:
  } */

    .price {
        font-size: 2.6rem;
    }
}
