h1,
h2,
h3,
h4,
h5,
h6,
body,
small {
    font-family: var(--segoe);
}

h1,
h2 {
    font-size: 36px;
    font-weight: bold;
}

h1,
h3,
h5 {
    text-transform: uppercase;
}

h3,
h4 {
    font-size: 26px;
}

body,
h6 {
    font-size: 16px;
}

small {
    font-size: 14px;
    font-style: italic;
}

h5 {
    font-size: 20px;
}
