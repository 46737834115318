.row {
    padding: 2rem 4rem;
    border-bottom: 1px solid var(--grey2);
    border-left: 0.5rem solid var(--grey2);
}

.innerRow {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: nowrap;
}

.hasPointer {
    cursor: pointer;
}

.hasPointer:hover {
    border-left: 0.5rem solid var(--grey1);
    background-color: var(--grey3);
}

.unread {
    border-left: 0.5rem solid var(--green);
}

.bar {
    display: flex;
    justify-content: space-between;
}

.tag *,
.badge * {
    font-family: var(--segoe) !important;
}

.tag svg {
    fill: var(--darkBlue);
}

.content {
    display: flex;
    flex-direction: column;
    flex: 1;
    gap: 2rem;
}

.info {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 2rem;
}

.info .title {
    font-size: 1.6rem;
    line-height: 2.1rem;
    font-family: var(--segoe-semibold);
    color: var(--darkBlue);
    text-transform: unset;
}

.info > .date {
    display: none;
}

.bar > .date {
    display: block;
}

.date {
    color: var(--grey1);
    font-size: 1.4rem;
    line-height: 2.5rem;
}

.text p {
    font-size: 1.4rem;
    line-height: 1.9rem;
    color: var(--darkBlue);
}

.avatar > div {
    min-width: 3.8rem;
    min-height: 3.8rem;
    border: 1px solid var(--grey2);
}

.indicators {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 2rem;
}

.link {
    display: inline;
    font-size: 1.4rem;
    color: var(--linkBlue) !important;
    font-family: var(--segoe-semibold);
    margin-top: auto !important;
}

@media (max-width: 599px) {
    .row {
        padding: 1rem;
    }

    .innerRow {
        align-items: flex-start;
    }

    .avatar {
        margin-right: 0;
    }

    .info {
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 1rem;
    }

    .info > .date {
        display: block;
    }

    .bar {
        margin-bottom: 1rem;
    }

    .bar > .date {
        display: none;
    }

    .indicators {
        gap: 1rem;
    }
}
