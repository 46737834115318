.intro {
    background-color: var(--grey3);
    padding-top: 3rem;
    padding-bottom: 3.6rem;
}

.noPadding {
    padding: unset;
}

.card {
    padding: 6rem 6rem;
    margin-top: 2rem;
}

.header {
    font-size: 3.2rem;
    font-family: var(--segoe);
    color: var(--darkBlue);
    text-transform: uppercase;
    margin-bottom: 3rem;
}

.markAsReadLink {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: var(--linkBlue) !important;
    font-family: var(--segoe-semibold);
}

.markAsReadLink:hover {
    text-decoration: unset !important;
}

.markAsReadLink .icon {
    margin-left: 0.5rem;
}

.alert {
    align-items: center;
}

.alertMessage {
    font-family: var(--segoe-semibold);
    font-size: 1.4rem;
}

@media (max-width: 959px) {
    .card {
        padding: 2rem 2rem;
    }
}

@media (max-width: 599px) {
    .toolbarFirst {
        padding-bottom: 0.8rem !important;
    }

    .toolbarLast {
        padding-top: 0.8rem !important;
    }
    /*     .inner {
        padding-top: 2rem;
    }

    .card {
        padding: 0;
    }

    .icon {
        margin-bottom: 1rem;
    } */
}
