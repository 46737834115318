/*GENERAL*/
p {
    font-size: 1.6rem;
}

.heroRowBox {
    display: flex;
    align-items: center;
    margin-top: 4rem;
    margin-bottom: 2rem;
}
.icon {
    width: 3rem;
    height: 3rem;
    margin-right: 2rem;
}
.title {
    font-size: 1.8rem;
    font-family: var(--segoe-bold);
    color: var(--darkBlue);
    text-transform: uppercase;
}
.text {
    font-family: var(--segoe);
    color: var(--darkBlue);
    margin-bottom: 2rem;
}
.heroBoxElement {
    padding-left: 10%;
    padding-right: 10%;
}
.hero {
    background-color: var(--darkBlue);
    height: calc(100vh - 138px);
    display: flex;
    flex-direction: column;
}

.hero .heroWrapper {
    flex: 1;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.hero .heroContent {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
}

.hero h1 {
    font-size: 3.8rem;
    font-family: var(--segoe-semibold);
    line-height: 5.1rem;
    color: #fff;
    margin-bottom: 8rem;
    text-transform: none;
}

.hero p {
    font-size: 1.6rem;
    font-family: var(--segoe);
    line-height: 2.1rem;
    color: #fff;
}

.hero .image {
    max-width: 100%;
}

.hero .stores {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
}

.hero .buttonAuth {
    margin: unset;
    margin-top: 5rem;
    min-width: 35.5rem;
    max-width: 100%;
    height: 4rem;
}

.commercial {
    background-color: var(--green);
    padding: 8rem 0;
}

.commercial h2,
.commercial h3,
.commercial p,
.commercial a {
    color: #fff;
}

.commercial h2 {
    font-size: 3.8rem;
    font-family: var(--segoe-semibold);
    margin-bottom: 2rem;
}

.commercial h3 {
    font-size: 2rem;
    margin-bottom: 4rem;
    font-family: var(--roboto);
}

.commercial p {
    font-family: var(--roboto);
    line-height: 1.4;
}

.commercial {
    text-align: center;
}

.commercial .button {
    padding: 0;
    margin-bottom: 5.2rem;
    height: 4rem;
    line-height: 4rem;
    font-family: var(--segoe-semibold);
}

.benefit {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.benefit .greenCircle {
    width: 8rem;
    height: 8rem;
    background-color: var(--green4);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 2rem;
}

.benefit .tick {
    width: 3rem;
    height: 2.5rem;
}

.benefitsContainer {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: 1;
    grid-column-gap: 24px;
    grid-row-gap: 0;
    margin-bottom: 4rem;
}

.benefit .content {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
}

.benefit .benefitTitle {
    font-size: 1.8rem;
    font-family: var(--segoe-semibold);
    color: var(--green);
    margin-bottom: 1rem;
    text-align: center;
    min-height: 4.8rem;
    width: 80%;
}

.benefit .benefitText {
    font-size: 1.4rem;
    font-family: var(--segoe);
    font-weight: 400;
    color: var(--darkBlue);
    text-align: center;
}

.benefits {
    padding: 0 0 8rem;
}

.benefits .button {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 4rem;
    line-height: 4rem;
    background-color: var(--darkBlue);
    color: #fff;
    font-family: var(--segoe-semibold);
}

.services {
    padding-bottom: 8rem;
}

.list {
    margin-bottom: 3rem;
}

.list li {
    display: flex;
    padding-bottom: 1rem;
    padding-top: 1rem;
    border-bottom: 1px solid #eee;
    justify-content: space-between;
}

.list li span {
    font-size: 1.6rem;
    font-family: var(--segoe);
    color: var(--darkBlue);
    line-height: 3rem;
}

.left {
    font-weight: 300;
}

.right {
    font-weight: 700;
    text-align: right;
}

.subscriptions,
.doctors,
.testimonial {
    padding-bottom: 8rem;
}

.doctors,
.testimonial,
.institutions,
.newsletter {
    background-color: #fff;
    padding-top: 1rem;
}

.institutions {
    padding-bottom: 8rem;
}

.institutions .button {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 4rem;
    line-height: 4rem;
    background-color: var(--darkBlue);
    color: #fff;
    font-family: var(--segoe-semibold);
    margin: 4.8rem auto 0;
}

.doctors .lightText,
.dutyFinder .lightText {
    font-family: var(--roboto);
    font-size: 1.6rem;
    color: var(--darkBlue);
    line-height: 2.4rem;
    font-weight: 300;
}

.doctors .doctorsLightText,
.dutyFinder .doctorsLightText {
    margin-bottom: 5rem;
    font-family: var(--segoe);
}

.doctors .doctorsBiggerText,
.dutyFinder .doctorsBiggerText {
    font-family: var(--segoe-bold);
    font-size: 2rem;
    color: var(--darkBlue);
    line-height: 3.5rem;
    margin-bottom: 2.5rem;
}

.doctors .imageWrapper {
    order: 1;
    padding: 0;
}

.doctors .placeholder {
    order: 2;
}

.doctors .contentWrapper {
    order: 3;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
}

.doctors .button {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 4rem;
    line-height: 4rem;
    background-color: var(--darkBlue);
    color: #fff;
    font-family: var(--segoe-semibold);
    margin: unset;
}

.dutyFinder .dutyContentWrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
}

.doctors .image,
.dutyFinder .image {
    width: 100%;
    object-fit: cover;
    border-radius: 1.2rem;
}

.dutyFinder .button {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 4rem;
    line-height: 4rem;
    background-color: var(--darkBlue);
    color: #fff;
    font-family: var(--segoe-semibold);
    margin: unset;
    border-color: var(--darkBlue);
}

.navigationItemContainer {
    height: 100%;
    display: flex;
    align-items: center;
}

.navigationItem {
    width: 5.4rem;
    height: 5.4rem;
    border: 1px solid #7e9591;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.navigationItem:hover {
    cursor: pointer;
}

.sliderElement {
    padding-top: 3rem;
    padding-bottom: 3rem;
}

.sliderElement .sliderText {
    font-size: 2.6rem;
    font-family: var(--segoe);
    font-style: italic;
    color: var(--green);
    line-height: 3.5rem;
    text-align: center;
    width: 70%;
    margin: 0 auto;
    position: relative;
}

.sliderElement .image {
    width: 6rem;
    height: 6rem;
    object-fit: cover;
    object-position: center;
    border: 2px solid var(--green);
    border-radius: 50%;
    margin-right: 4rem;
}

.sliderElement .imageRow {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 5rem;
}

.sliderElement .name {
    font-size: 2rem;
    font-family: var(--roboto);
    font-weight: 300;
    color: var(--darkBlue);
}

.quotesTop {
    width: 10rem;
    position: absolute;
    top: -3rem;
    left: -4rem;
}

.quotesBottom {
    width: 10rem;
    position: absolute;
    bottom: -4rem;
    right: -4rem;
}

.dutyFinder {
    padding-bottom: 8rem;
}

.inputContainer {
    width: 100%;
    margin-top: 2.5rem;
}

.inputContainer .input {
    width: 100%;
    border-radius: 4px;
}

.inputContainer .label {
    font-size: 1.4rem;
    font-family: var(--segoe);
    color: var(--romanSilver);
    text-transform: uppercase;
    margin-bottom: 1rem;
}

.link {
    color: var(--linkBlue);
    text-decoration: none;
    font-weight: 700;
}

.newsletter {
    padding-bottom: 8rem;
}

.newsletter .link {
    color: var(--darkBlue);
    font-family: var(--segoe-semibold);
}

.newsletter .button {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 4rem;
    line-height: 4rem;
    background-color: var(--darkBlue);
    border-color: var(--darkBlue);
    color: #fff;
    font-family: var(--segoe-semibold);
}

.twoColumns {
    padding-bottom: 12rem;
    background-color: #fff;
    padding-top: 1rem;
}
.twoColumns img {
    width: 100%;
    object-fit: cover;
    border-radius: 1.2rem;
}
.twoColumns .button {
    display: flex;
    align-items: center;
    justify-content: center;
    font: 600 0.875em / 2.85 var(--segoe);
    width: 44.4ch;
    margin-inline: unset;
    margin-top: 2.85em;
}
.twoColumns ul {
    display: flex;
    flex-direction: column;
    gap: 1.5em;
}
.twoColumns li {
    display: inline-grid;
    grid-template-columns: auto 1fr;
    color: var(--darkBlue);
}
.twoColumns li::before {
    content: "";
    display: inline-block;
    background-image: url("../../assets/images/icons/tickGreen.svg");
    background-size: contain;
    background-repeat: no-repeat;
    width: 1em;
    height: 1em;
    margin-right: 0.33em;
    margin-top: 0.33em;
}

@media only screen and (max-width: 959px) {
    .hero {
        height: auto;
        padding: 3rem 0 0;
    }

    .hero h1 {
        font-size: 2.5rem;
        font-family: var(--segoe-semibold);
        margin-bottom: 4rem;
        line-height: normal;
    }

    .hero .heroWrapper .buttonAuth {
        margin: 4rem auto 5.2rem;
        width: 100%;
    }

    .hero .stores {
        margin-bottom: 4rem;
        gap: 0.8rem;
    }

    .commercial {
        padding: 4rem 0;
    }

    .commercial h2 {
        font-size: 3.4rem;
        line-height: normal;
        margin-bottom: 2.5rem;
    }

    .commercial p {
        font-size: 1.4rem;
    }

    .benefitsContainer {
        flex-wrap: wrap;
        grid-template-columns: repeat(1, 1fr);
        grid-template-rows: 4;
    }

    .benefit {
        flex-direction: row;
        justify-content: flex-start;
        margin: 0 0 3rem;
    }

    .benefit:last-child {
        margin-bottom: 0;
    }

    .benefit .greenCircle {
        width: 5.8rem;
        height: 5.8rem;
        background-color: var(--green2);
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 1rem;
        margin-bottom: 0;
    }

    .benefit .tick {
        width: 2.4rem;
    }

    .benefit .content {
        justify-content: flex-start;
        align-items: flex-start;
        text-align: left;
        flex: 1;
    }

    .benefit .benefitTitle {
        margin-bottom: 1rem;
        line-height: normal;
        min-height: auto;
        text-align: left;
        font-size: 1.6rem;
    }

    .benefit .benefitText {
        font-family: var(--segoe);
        font-weight: 300;
        font-size: 1.4rem;
        text-align: left;
        line-height: normal;
    }

    .subscriptions .button {
        align-self: center;
        width: 20rem;
        margin: 0;
        line-height: 4rem;
    }

    .subscriptions .card {
        margin-bottom: 3rem;
    }

    .twoColumns .image,
    .doctors .image,
    .dutyFinder .image {
        margin-bottom: 3rem;
    }

    .doctors .lightText,
    .dutyFinder .lightText {
        font-size: 1.4rem;
        margin-bottom: 0;
    }

    .twoColumns .button,
    .doctors .button,
    .dutyFinder .button,
    .institutions .button,
    .newsletter .button {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 3rem;
        width: 100%;
        max-width: 50rem;
    }

    .newsletter .button {
        margin-top: 0;
    }

    .sliderElement .sliderText {
        font-size: 1.6rem;
        width: 100%;
        line-height: normal;
    }

    .quotesTop {
        left: 0;
    }

    .quotesBottom {
        right: 0;
    }

    .sliderControls {
        display: flex;
        justify-content: center;
    }

    .navigationItemContainer {
        margin: 0 2rem;
    }

    .mobileHeroboxes {
        background-color: #fff;
    }

    .benefits,
    .services,
    .subscriptions,
    .dutyFinder {
        padding-top: 0.1rem;
        padding-bottom: 12rem;
        background-color: var(--cloud);
    }

    .benefits {
        padding: 4.2rem 0;
    }

    .benefits .button {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 0;
        width: 100%;
    }
}

@media screen and (max-width: 599px) {
    .hero h1 {
        font-size: 2.5rem;
        font-family: var(--segoe-semibold);
        margin-bottom: 4rem;
        line-height: normal;
    }

    .commercial .button {
        width: 100% !important;
    }

    .benefit {
        width: 100%;
        margin: 0 0 3rem;
    }
}
