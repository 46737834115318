.outer {
    max-height: calc(100% - 80px) !important;
}

.commercial {
    background-color: var(--green);
    padding: 6rem 0;
    text-align: center;
    padding: 4rem 10rem 6rem;
    border-radius: 1.2rem;
}

.commercial h2,
.commercial h3,
.commercial p,
.commercial a {
    color: #fff;
}

.commercial h2 {
    font-size: 6.8rem;
    font-family: var(--segoe-bold);
    margin-bottom: 2rem;
    line-height: normal;
}

.commercial h3 {
    font-size: 2rem;
    margin-bottom: 4rem;
    font-family: var(--roboto);
}

.commercial p {
    font-family: var(--roboto);
    line-height: normal;
}

.commercial .button {
    margin-bottom: 3rem;
    margin-top: 4rem;
}

.modalBody {
    padding: 0 !important;
}

.topHalf {
    position: relative;
    padding: 4rem;
    background-color: var(--darkBlue);
}

.benefit {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 1.4rem;
    width: 25%;
}

.benefit .greenCircle {
    width: 11rem;
    height: 11rem;
    background-color: var(--green2);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 2rem;
}

.benefit .tick {
    width: 4.6rem;
    height: 4rem;
}

.benefitsContainer {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-bottom: 3rem;
}

.benefit .benefitTitle {
    font-size: 1.8rem;
    font-family: var(--segoe-bold);
    color: var(--green);
    margin-bottom: 1rem;
    text-align: center;
    min-height: 4.8rem;
    text-transform: uppercase;
    width: 100%;
}

.benefit .benefitText {
    font-size: 1.6rem;
    font-family: var(--roboto);
    font-weight: 300;
    color: var(--darkBlue);
    text-align: center;
    line-height: normal;
}

.benefits .button {
    margin-bottom: 12rem;
}

.secondHalf {
    background-color: #f8f8f8;
    padding: 1rem 4rem 5rem;
}

.list {
    margin-bottom: 3rem;
}

.list li {
    display: flex;
    padding-bottom: 1rem;
    padding-top: 1rem;
    border-bottom: 1px solid #eee;
    justify-content: space-between;
}

.list li span {
    font-size: 1.6rem;
    font-family: var(--segoe);
    color: var(--darkBlue);
    line-height: 3rem;
}

.left {
    font-weight: 300;
}

.right {
    font-weight: 700;
    text-align: right;
}

.subscriptions {
    margin-top: 12rem;
}

.card {
    min-height: 93rem;
}

.inputContainer {
    margin-top: 6rem;
}

.inputContainer .input {
    width: 100%;
    border-radius: 4px;
}

.inputContainer .label {
    font-size: 1.4rem;
    font-family: var(--segoe);
    color: #7e9591;
    text-transform: uppercase;
    margin-bottom: 1rem;
}

.button {
    margin-top: 6rem;
}

.redeemButton {
    margin-top: 2rem;
}

.successfulRedeem {
    padding: 3rem 0rem;
    font-size: 1.6rem;
    font-family: var(--roboto);
    color: var(--darkBlue);
}

.successfulRedeem span {
    font-weight: 700;
}

@media (max-width: 959px) {
    .commercial {
        padding: 2rem 1rem;
    }

    .commercial h2 {
        font-size: 2.6rem;
    }

    .topHalf {
        padding: 4.2rem 1.2rem;
    }

    .secondHalf {
        padding: 2rem;
    }

    .commercial h3,
    .commercial p {
        font-size: 1.4rem;
    }

    .benefit {
        width: 100%;
        margin-bottom: 3rem;
    }

    .benefit .greenCircle {
        width: 5.8rem;
        height: 5.8rem;
    }

    .benefit .tick {
        width: 2.4rem;
    }

    .benefit .benefitTitle {
        min-height: auto;
    }

    .subscriptions {
        margin-top: 3rem;
    }

    .card {
        margin-bottom: 3rem;
        min-height: auto;
    }

    .inputContainer {
        margin-top: 2rem;
    }
}
