.pageHeader {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 2rem;
    position: relative;
}

.closeContainer:hover {
    cursor: pointer;
}

.text {
    width: 100%;
    font-size: 3.8rem;
    font-family: var(--segoe-semibold);
    color: var(--darkBlue);
    line-height: 5.1rem;
    white-space: nowrap;
    text-transform: unset;
}

@media (max-width: 599px) {
    .pageHeader {
        margin-bottom: 2rem;
    }

    .text {
        font-size: 1.6rem;
        flex: 1;
        line-height: normal;
        white-space: normal;
    }
}
