.sliderContainer {
    position: relative;
}

.navigation {
    width: 5rem;
    height: 5rem;
    border-radius: 50%;
    border: 1px solid #263d50;
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
}

.navigation:hover {
    cursor: pointer;
}

.icon {
    width: 4rem !important;
    height: 4rem !important;
}

.navigationLeft {
    position: relative;
    top: 4rem;
}

.navigationRight {
    position: relative;
    top: 4rem;
    margin-left: auto;
}

.doctorContainer:hover {
    cursor: pointer;
    outline: none;
}

.navigationLeft .icon {
    margin-left: -0.2rem;
}

.navigationRight .icon {
    margin-right: -0.2rem;
}

@media (max-width: 960px) {
    .navigation {
        position: static;
        margin: 0 2rem;
    }

    .navigationContainer {
        display: flex;
        justify-content: center;
        margin-bottom: 20px;
        margin-top: 3rem;
    }
}
