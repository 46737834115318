.PersonIntro {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.imageContainer {
    width: 100%;
    position: relative;
    height: 15rem;
}

.image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    border-radius: 12px;
}

.availability {
    position: absolute;
    bottom: 1rem;
    left: 1rem;
    background-color: #929ea7;
    color: #fff;
    font-size: 1.4rem;
    /* line-height: 2rem; */
    padding: 0.5rem 1rem;
    font-family: var(--segoe);
    font-weight: 700;
}

.availability.available {
    background-color: #fff;
    color: var(--green);
}

.name {
    font-size: 2rem;
    font-family: var(--segoe);
    color: var(--darkBlue);
    line-height: 2.3rem;
    margin-top: 1rem;
    height: 5rem;
}

.qualification {
    font-size: 1.6rem;
    font-family: var(--segoe);
    color: #929ea7;
}

@media (max-width: 599px) {
    .name {
        font-size: 1.6rem;
        min-height: 5rem;
    }
}
