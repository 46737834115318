.header {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin-bottom: 3rem;
}

.title {
    font-size: 3.6rem;
    font-family: var(--segoe);
    color: var(--darkBlue);
    line-height: normal;
    text-transform: uppercase;
}

.identifier {
    font-size: 1.6rem;
    font-family: var(--roboto);
    color: var(--darkBlue);
}

.inner {
    width: 100%;
    height: 60vh;
    border: 1px solid var(--grey);
    padding: 2rem;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    background-color: #fff;
}

.startDummy,
.endDummy {
}

@media (max-width: 599px) {
    .header {
        flex-direction: column;
        align-items: flex-start;
        border-bottom: 1px solid var(--grey);
        padding-bottom: 2rem;
    }

    .title {
        font-size: 2.6rem;
        margin-bottom: 2rem;
    }

    .inner {
        border: none;
    }
}
