.reservationCalendar {
    padding: 1.3rem;
    border-radius: 1.2rem;
    background-color: #fff;
    box-shadow: 0 3px 10px #0000000d;
    overflow: hidden;
    margin-bottom: 3rem;
}

.header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 3.2rem;
}

.datas {
    flex: 1;
    margin-right: 2rem;
}

.imageContainer {
    width: 26rem;
    height: 18rem;
    border-radius: 1.2rem;
    overflow: hidden;
}

.image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
}

.doctorName {
    font-size: 2.4rem;
    font-family: var(--segoe);
    line-height: 3.2rem;
    color: var(--darkBlue);
    margin-bottom: 2rem;
    text-transform: uppercase;
}

.data {
    font-size: 1.4rem;
    font-family: var(--segoe);
    color: #929ea7;
    line-height: normal;
}

.dataHeader {
    font-size: 1.4rem;
    font-family: var(--segoe);
    color: var(--darkBlue);
    line-height: normal;
}

.line {
    width: 100%;
    height: 1px;
    background-color: #929ea7;
    margin: 1rem 0;
    opacity: 0.2;
}

.calendarOuter {
    border: 1px solid #707070;
    border-radius: 1.2rem;
    overflow: hidden;
}

.weekPicker {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem 4rem;
    border-bottom: 1px solid #707070;
}

.weekData {
    font-size: 1.6rem;
    font-family: var(--segoe);
    color: var(--darkBlue);
    word-spacing: 0.3rem;
}

.slideControl {
    width: 3rem;
    height: 3rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    border: 1px solid var(--darkBlue);
}

.slideControl:hover {
    cursor: pointer;
}

.reservationCalendar .slideIcon {
    width: 2.5rem;
    height: 2.5rem;
}

.mobileImageContainer {
    display: none;
}

.loaderContainer {
    height: 30rem;
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.loaderContainer > p {
    margin-top: 2rem;
}

@media screen and (max-width: 599px) {
    .imageContainer {
        display: none;
    }

    .nameContainer {
        display: flex;
        justify-content: space-between;
    }

    .mobileImageContainer {
        display: block;
        width: 14rem;
        height: 9rem;
        border-radius: 0.6rem;
        overflow: hidden;
    }

    .doctorName {
        font-size: 2rem;
        line-height: 2.7rem;
    }

    .data,
    .dataHeader {
        font-size: 1.2rem;
    }
}
