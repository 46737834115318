.PageSubheader {
    color: var(--darkBlue);
    font-family: var(--roboto);
    font-weight: 300;
    font-size: 1.6rem;
    margin-bottom: 6rem;
    width: 70%;
    /* text-align: center; */
    margin-left: auto;
    margin-right: auto;
    text-align: center;
}

@media (max-width: 599px) {
    .PageSubheader {
        width: 100%;
        margin-bottom: 2rem;
    }
}
