.alert {
    border: 1px solid var(--amaranth1);
    background-color: var(--amaranth2);
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 1rem;
    padding: 1rem 4rem;
    margin-bottom: 2rem;
    border-radius: 0.6rem;
}

.label {
    background-color: var(--amaranth);
    padding: 0.5rem 1rem;
    border-radius: 100px;
    color: #fff;
    font-size: 1.4rem;
    font-weight: 700;
}

.text {
    font-family: var(--font-sans);
    font-weight: 700;
    color: var(--amaranth);
}

@media (max-width: 768px) {
    .alert {
        flex-direction: column;
        align-items: flex-start;
        padding: 1rem;
    }
    .button {
        margin-left: unset;
    }
}
