ol.text {
    list-style: decimal;
    padding-left: 3rem;
}

ul.text {
    list-style: disc;
    padding-left: 3rem;
}

.text li {
    margin-bottom: 1.5rem;
}
