/* .pageHeader {
  margin-top: 0;
  padding-top: 5rem;
}

.topContainer {
  background-color: white;
  height: 50rem;
  width: 100%;
  margin-bottom: 4rem;
  margin-top: 4rem;
} */

.inner {
    padding-top: 5rem;
    padding-bottom: 5rem;
}

.card {
    padding: 6rem 6rem;
    margin-top: 2rem;
}

.header {
    font-size: 3.2rem;
    font-family: var(--segoe);
    color: var(--darkBlue);
    text-transform: uppercase;
    margin-bottom: 3rem;
}

.mainIcon {
    width: 7rem;
    height: 7rem;
}

.title {
    font-family: var(--segoe);
    color: var(--darkBlue);
    font-size: 1.8rem;
    font-weight: bold;
    margin-top: 2rem;
    margin-bottom: 2rem;
}

.cardText {
    font-size: 1.8rem;
    font-family: var(--roboto);
    font-weight: normal;
    color: #263d50;
    /* margin-bottom: 2rem; */
}

.text {
    font-size: 1.6rem;
    font-family: var(--segoe);
    color: #11a2ed;
    font-weight: bold;
    cursor: pointer;
    margin-top: 2rem;
    margin-bottom: 2rem;
}

.innerContainer {
    /* margin-bottom: 2rem; */
    /* display: flex; */
    /* justify-content: space-between;
  align-items: flex-start; */
}

.data {
    font-size: 1.6rem;
    color: #263d50;
    font-family: var(--segoe);
}

.label {
    margin-bottom: 0.5rem;
    color: #263d50;
    opacity: 0.5;
}

.icon {
    width: 4rem;
    height: 4rem;
}

@media (max-width: 959px) {
    .card {
        padding: 2rem 2rem;
    }
}

@media (max-width: 599px) {
    .inner {
        padding-top: 2rem;
    }

    .card {
        padding: 0;
    }

    .mainIcon {
        margin-bottom: 1rem;
    }
}
