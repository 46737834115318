.paymentSettings {
    margin-bottom: 3rem;
}

.paymentSettings p {
    color: var(--darkBlue);
}

.creditCards {
    margin-bottom: 2rem;
}

.subscriptions {
}

.alert {
    align-items: center;
    margin-bottom: 2rem;
}

.alertMessage {
    font-family: var(--segoe-semibold);
    font-size: 1.4rem;
}

.PageSubHeader {
    margin-bottom: 2rem;
}

.link {
    font-family: var(--segoe-semibold) !important;
    font-size: 1.6rem;
    color: var(--linkBlue) !important;
}

.button {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 4rem;
    line-height: 4rem;
    background-color: var(--green);
    color: #fff;
    font-family: var(--segoe-semibold);
    margin: unset;
}

.formLabel {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 1.4rem !important;
    line-height: 1.9rem !important;
    color: var(--darkBlue) !important;
    font-family: var(--segoe-semibold) !important;
    margin-bottom: 0.5rem !important;
}

.formLabel svg {
    fill: var(--linkBlue);
}

.formControl {
    width: 100%;
}

.terms {
    margin-bottom: 1rem !important;
    padding-left: 1rem;
    border-left: 5px solid var(--grey2);
    font-size: 1.4rem;
}

.termsLink {
    color: var(--linkBlue);
    font-family: var(--segoe-semibold);
}

.paymentGateways {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 2rem;
}

.paymentImage {
    height: 4rem;
    max-width: 100%;
}

.paymentInfo {
    font-size: 1.4rem;
    font-family: var(--segoe);
    font-style: italic;
    color: var(--grey1);
    text-align: center;
    margin-top: 1rem;
}

.responseText {
    margin-bottom: 1rem;
}
