.buttonAuth {
    width: 20.8rem;
    max-width: 100%;
    position: relative;
    z-index: 0;
    padding: 0 2rem;
}

.buttonAuth.open {
    z-index: 101;
}

.buttonAuth.open .buttonInner span {
    color: var(--green);
}

.notificationCounter {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute !important;
    left: 0;
    bottom: 0;
    transform: translateY(50%);
    z-index: 1;
    line-height: 2rem;
    color: #fff;
    background-color: var(--orange);
    border-radius: 10rem;
    padding: 0 0.7rem;
    min-width: 2.4rem;
    height: 2.4rem;
    font-size: 1.6rem;
    font-family: var(--segoe);
}

.buttonInner {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 3.8rem;
    line-height: 1.5;
}

.buttonInner span {
    color: #fff;
    font-family: var(--segoe-semibold);
    font-size: 1.4rem;
}

.buttonInner .icon {
    width: 2.2rem;
    margin-right: 1rem;
}

.clickDropDownOuter {
    z-index: 101;
    position: absolute;
    top: 120%;
    right: 0;
}

.clickDropDownInner {
    position: relative;
    padding-top: 2.23rem;
}

.clickDropDownInner .triangle {
    width: 1px;
    height: 1px;
    border: 2.2rem solid transparent;
    transform: rotateZ(45deg);
    position: absolute;
    top: 0;
    right: 0.9rem;
    border-top-color: #e5e5e5;
}

.clickDropDownInner .elements {
    background-color: #fff;
    padding: 1rem 2rem;
}

.clickDropDownInner .element {
    white-space: nowrap;
    color: var(--darkBlue);
    font-size: 1.6rem;
    font-family: var(--segoe);
    display: flex;
    border-bottom: 1px solid rgba(38, 61, 80, 0.1);
    line-height: 4rem;
}

.clickDropDownInner .element:hover {
    /* background-color: var(--grey); */
}

.clickDropDownInner .element:last-child {
    border-bottom: none;
}

.clickDropDownInner .icon {
    width: 2.4rem;
    margin-right: 1rem;
    align-self: center;
}
