.typo-container * {
    all: revert;
    color: var(--darkBlue);
}

.typo-container h1 {
    font-size: 2.4rem;
    font-family: var(--segoe);
    margin-top: unset;
    margin-bottom: 1.6rem;
    /* color: var(--green); */
}

.typo-container h2 {
    font-size: 2.2rem;
    font-family: var(--segoe);
    margin-top: unset;
    margin-bottom: 1.2rem;
    /* color: var(--green); */
}

.typo-container h3 {
    font-size: 2rem;
    font-family: var(--segoe);
    margin-top: unset;
    /* color: var(--green); */
}

.typo-container h4 {
    font-size: 1.6rem;
    font-family: var(--segoe);
    margin-top: unset;
    /* color: var(--green); */
}

.typo-container h5 {
    font-size: 12.8rem;
    font-family: var(--segoe);
    margin-top: unset;
    /* color: var(--green); */
}

.typo-container h6 {
    font-size: 11.2rem;
    font-family: var(--segoe);
    margin-top: unset;
    /* color: var(--green); */
}

.typo-container p {
    font-size: 1.6rem;
    font-family: var(--segoe);
    line-height: 2.4rem;
    margin-top: unset;
    margin-bottom: 3.2rem;
}

.typo-container ul,
.typo-container ol {
    padding-left: 1.6rem;
    margin: 0;
    margin-bottom: 3.2rem;
}

.typo-container li {
    font-size: 1.6rem;
    font-family: var(--segoe);
    padding-left: 0.8rem;
    margin-bottom: 0.8rem;
}

@media (max-width: 599px) {
    .typo-container h1 {
        font-size: 2rem;
        margin-bottom: 1.4rem;
    }

    .typo-container h2 {
        font-size: 1.8rem;
        line-height: 2.4rem;
    }

    .typo-container h3 {
        font-size: 1.6rem;
        line-height: 2rem;
    }

    .typo-container h4 {
        font-size: 1.4rem;
        line-height: 1.8rem;
    }

    .typo-container p {
        font-size: 1.4rem;
        line-height: 2.1rem;
    }

    .typo-container ul,
    .typo-container ol {
        margin-bottom: 2rem;
    }

    .typo-container li {
        font-size: 1.4rem;
    }
}
