.FooterInfo {
    background-color: #263d50;
    padding: 6rem 0;
}

.colHeader,
.accordionInner {
    font-size: 1.6rem;
    font-family: var(--segoe-bold);
    color: #fff;
    text-transform: uppercase;
    margin-bottom: 3rem;
    background-color: transparent;
}

.accordionInner {
    margin-bottom: 0;
}

.link {
    font-size: 1.6rem;
    font-family: var(--roboto);
    font-weight: 300;
    margin-bottom: 2rem;
    display: block;
}

.link,
.link:visited,
.link:active {
    text-decoration: none;
    color: #fff;
}

.link:hover {
    text-decoration: underline;
    cursor: pointer;
}

.icon {
    color: #fff;
    height: 1.8rem;
    margin-right: 1.5rem;
}

.socialLink {
    display: flex;
    align-items: center;
}

.accordion {
    background-color: transparent !important;
    border-bottom: 1px solid rgba(255, 255, 255, 0.4);
    box-shadow: none !important;
}

.accordion:last-child {
    border-bottom: none;
}

.accordion * {
    box-shadow: none !important;
    border-bottom: none !important;
}

.accordionSummary {
    background-color: transparent;
}

@media (max-width: 959px) {
    .FooterInfo {
        padding: 2rem 0;
    }
}

@media (max-width: 599px) {
    .FooterInfoInner {
        flex-direction: column;
        align-items: center;
        height: auto;
        padding: 0 0 2rem;
    }
}
