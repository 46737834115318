.formControl {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 0.4rem;
}

.formControl p {
    font-size: 1.4rem;
    color: var(--darkBlue);
    display: block;
    width: 2.2rem;
    margin-left: 0.4rem;
}

.formControl p.off {
    color: var(--grey);
}
