.panel {
    border: 1px solid var(--grey2);
    border-radius: 0.4rem;
    padding: 1rem;
    margin-bottom: 2rem;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    flex: 1;
}

.panel.lg {
    padding: 2rem 1rem;
}

.panel.row {
    flex-direction: row;
    align-items: center;
    gap: 1rem;
}

.panel.column {
    flex-direction: column;
}

.panel p {
    color: var(--darkBlue);
    margin-bottom: 0.4rem;
}

.panel p:last-child {
    margin-bottom: 0;
}
