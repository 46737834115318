.SocialButton {
    display: flex;
    width: 100%;
    height: 4rem;
    border: 1px solid #bdc4ca;
    border-radius: 6px;
    align-items: center;
    justify-content: center;
    position: relative;
}

.SocialButton:hover {
    cursor: pointer;
}

.image {
    position: absolute;
    left: 1rem;
    width: 2.5rem;
    height: 2.5rem;
}

.text {
    font-size: 1.6rem;
    font-family: var(--roboto);
}
