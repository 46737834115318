.accordion {
    box-shadow: unset !important;
    border: 1px solid var(--grey2) !important;
}

.summary {
    padding: 1rem 4rem !important;
}

.content {
    justify-content: flex-start !important;
    align-items: center !important;
    gap: 1rem !important;
}

.heading {
    font-size: 1.6rem !important;
    font-family: var(--segoe-semibold) !important;
    color: var(--darkBlue) !important;
}

.expandIcon {
    border: 1px solid var(--linkBlue) !important;
    border-radius: 0.3rem !important;
    width: 3rem !important;
    height: 3rem !important;
    flex: 0 0 3rem !important;
    color: var(--linkBlue) !important;
    transform: unset !important;
    position: relative;
    left: -10px;
}

.details {
    padding: 1rem 4rem 4rem !important;
    color: var(--darkBlue);
}

.details dl dt {
    font-size: 1.6rem;
    font-family: var(--segoe-semibold);
    margin-bottom: 1rem;
}

.details dl dd {
    margin-bottom: 2rem;
}

.details dl dd ul {
    margin-left: 2rem;
    list-style: disc;
}

.details dl dd ul li {
    margin-bottom: 2rem;
}

.link {
    display: inline;
    font-size: 1.6rem;
    color: var(--linkBlue) !important;
    font-family: var(--segoe-semibold);
    margin-top: auto !important;
}

.linkCancel {
    color: var(--gray1);
    font-size: 1.4rem;
    margin-top: unset !important;
}

.submit {
    display: inline;
    font-size: 1.4rem;
    border-radius: 100rem;
    padding: 0.5em 1rem;
    background-color: var(--green);
    color: #fff;
    font-family: var(--segoe-semibold);
    border: unset;
    cursor: pointer;
}
.submit:disabled {
    background-color: var(--grey3);
    color: var(--grey1);
    pointer-events: none;
}

.gridFooter {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.gridContainer {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 2rem;
    flex-basis: 100%;
}

.gridItem {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-direction: column;
    height: 100%;
}

.gridItem1 {
    flex: 1;
    border-right: 1px solid var(--grey2);
    padding-right: 2rem;
}
.gridItem2 {
    flex: 2;
    border-right: 1px solid var(--grey2);
    padding-right: 2rem;
}
.gridItem3 {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.tdoc {
    width: 3.5rem;
}

.showDetails {
    margin-left: auto;
    margin-right: 2rem;
}

.showDetails p {
    color: var(--linkBlue);
    font-family: var(--segoe-semibold);
}

.packages {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 2rem;
}

.packages li {
    display: flex;
    align-items: center;
    padding: 0.2rem 0;
    display: flex;
}

.packages li p {
    display: flex;
    align-items: center;
    font-size: 1.4rem;
    padding-left: 0.5rem;
}

.packages li:last-child {
    border-bottom: unset;
}

.packages li .link {
    font-size: 1.4rem;
}

@media (max-width: 599px) {
    .summary {
        padding: 1rem 1.8rem !important;
    }

    .details {
        padding: 1rem 1.8rem 1.8rem !important;
    }

    .gridContainer {
        flex-direction: column;
        gap: 4rem;
    }

    .gridItem1,
    .gridItem2,
    .gridItem3 {
        width: 100%;
        border-right: unset;
        padding-right: unset;
    }

    .showDetails {
        display: none;
    }
}
