.container {
    width: 100%;
}

.qualificationGroupTitle {
    text-transform: uppercase;
    color: var(--green);
    font-size: 2.6rem;
    font-family: var(--segoe-bold);
    margin-bottom: 1.5rem;
    margin-top: 2.5rem;
}

.qualificationGroupElement {
    font-size: 1.6rem;
    font-family: var(--segoe);
    color: var(--darkBlue);
    margin-bottom: 1.5rem;
}

.qualificationGroupElement:hover {
    cursor: pointer;
    /* font-family: var(--segoe-bold); */
    color: var(--green);
}

.qualifications {
    column-count: 4;
}

.qualificationGroup {
    display: inline-block;
    width: 100%;
    /* width: 25%; */
}

.noMatch {
    margin-top: 6rem;
    color: var(--darkBlue);
    font-size: 2.4rem;
    font-family: var(--roboto);
    font-weight: 700;
    text-align: center;
}

.altNoMatch {
    margin-top: 4rem;
    color: var(--darkBlue);
    font-size: 1.9rem;
    line-height: 2.3rem;
    font-family: var(--sagoe);
    /* font-weight: 700; */
    text-transform: uppercase;
    /* text-align: center; */
}
