.inner {
    padding-top: 5rem;
    padding-bottom: 5rem;
}

.card {
    padding: 6rem 10rem;
    margin-top: 2rem;
}

.title {
    font-size: 3.6rem;
    font-family: var(--segoe);
    color: var(--darkBlue);
    text-transform: uppercase;
    margin-bottom: 4rem;
}

.greenText {
    font-family: var(--roboto);
    font-size: 1.6rem;
    color: var(--green);
    font-weight: 700;
    line-height: 2.4rem;
    margin-bottom: 4rem;
}

.blackText {
    font-family: var(--roboto);
    font-size: 1.6rem;
    color: var(--darkBlue);
    line-height: 2.4rem;
    margin-bottom: 1rem;
}

.subtitle {
    font-family: var(--roboto);
    font-size: 1.7rem;
    font-weight: 700;
    color: var(--darkBlue);
    line-height: 2.4rem;
    margin-top: 4rem;
    margin-bottom: 2rem;
}

.lightText {
    font-family: var(--roboto);
    font-size: 1.6rem;
    color: var(--darkBlue);
    line-height: 2.4rem;
    font-weight: 300;
}

.doctorsLightText {
    margin-bottom: 5rem;
}

.doctorsBiggerText {
    font-family: var(--segoe);
    font-size: 2.6rem;
    color: var(--darkBlue);
    line-height: 3.5rem;
    margin-bottom: 2.5rem;
}

.image {
    width: 100%;
    object-fit: cover;
}

.doctorsButton {
    background: #fff;
    border-color: var(--darkBlue);
    color: var(--darkBlue);
}

.doctorsButton:hover {
    background: #fff;
}

.pageHeader {
    margin-top: 12rem;
}

.ListingCard {
    width: 96%;
    margin-left: auto;
    margin-right: auto;
}

.institutionsSubheader {
    margin-bottom: 8rem;
    text-align: center;
    width: 60%;
    margin-right: auto;
    margin-left: auto;
}

.institutionsButton {
    margin-left: auto;
    margin-right: auto;
    margin-top: 6rem;
    background: #fff;
    border-color: var(--darkBlue);
    color: var(--darkBlue);
}

.institutionsButton:hover {
    background: #fff;
}

.popularQuestion {
    display: flex;
    width: 100%;
    margin-bottom: 4rem;
}

.icon {
    width: 20px;
    height: 20px;
}

.popularLeft {
    margin-right: 1rem;
}

.popularText {
    font-size: 1.6rem;
    color: var(--darkBlue);
    font-size: 1.6rem;
    font-family: var(--roboto);
    font-weight: 700;
    margin-bottom: 2rem;
}

.popularLink {
    font-size: 1.4rem;
    font-family: var(--segoe);
    font-weight: 700;
    color: #11a2ed;
    text-decoration: none;
}

.popularQuestionsHeaderRow {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    margin-top: 4rem;
    margin-bottom: 6rem;
}

.popularQuestionsHeader {
    font-size: 2rem;
    color: var(--darkBlue);
    font-family: var(--segoe);
    font-weight: 700;
    text-transform: uppercase;
}

.popularQuestionsLink {
    font-size: 1.6rem;
    color: var(--green);
    font-family: var(--segoe);
    font-weight: 700;
    text-decoration: none;
}

.popularQuestionsHeaderRowRight {
    display: flex;
    align-items: center;
}

.button {
    margin-top: 5rem;
}

@media (max-width: 959px) {
    .card {
        padding: 2rem 2rem;
    }

    .institutionsSubheader {
        width: 80%;
    }
}

@media (max-width: 599px) {
    .inner {
        padding-top: 2rem;
    }

    .title {
        font-size: 2.6rem;
        font-weight: 400;
        margin-bottom: 3rem;
    }

    .blackText,
    .greenText {
        margin-bottom: 3rem;
    }

    .card {
        padding: 0;
    }

    .button {
        width: 100%;
    }

    .institutionsSubheader {
        width: 100%;
        margin-bottom: 3rem;
    }

    .institutionsButton {
        width: 100%;
    }

    .image {
        margin-bottom: 4rem;
    }

    .doctorsBiggerText {
        font-family: var(--segoe);
        font-size: 2rem;
        color: var(--darkBlue);
        line-height: 2.7rem;
        margin-bottom: 2rem;
    }

    .lightText {
        font-size: 1.4rem;
        line-height: 1.9rem;
    }

    .pageHeader {
        margin-top: 6rem;
    }
}
