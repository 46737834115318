.AlertLine {
    padding: 2.8rem 2rem;
    background-color: var(--yellow);
    width: 100%;
    max-width: 100%;
    display: flex;
    align-items: center;
}

.AlertLine:hover {
    cursor: pointer;
}

.icon {
    height: 6rem;
}

.text {
    font-size: 1.8rem;
    color: var(--darkBlue);
    font-family: var(--roboto);
    font-weight: 700;
    line-height: 2.4rem;
    width: 70%;
    margin-left: 2rem;
}

.actionText {
    font-size: 1.6rem;
    color: var(--darkBlue);
    font-family: var(--segoe);
    font-weight: 700;
}

.actionTextContainer {
    display: flex;
    align-items: center;
    margin-left: auto;
}

.rightIcon {
    width: 3rem !important;
    height: 3rem !important;
}

@media (max-width: 599px) {
    .actionText,
    .icon {
        display: none;
    }

    .text {
        width: 90%;
    }
}
