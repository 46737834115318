.infoRow {
    padding: 1rem;
}

.image {
    width: 100%;
    height: 11.8rem;
    object-fit: cover;
    object-position: center;
}

.infoText {
    padding: 0 2rem;
}

.reservationBtn {
    background-color: #1fba9c;
    border: 1px solid #1fba9c;
    border-radius: 27px;
    height: 5.4rem;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.mobileBtn {
    display: flex;
    align-items: center;
    cursor: pointer;
}

@media (max-width: 599px) {
    .image {
        height: 5.9rem;
    }
}
