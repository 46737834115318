.radioWrapper {
    position: relative;
    padding: 0.4rem 1.2rem;
    width: 100%;
    height: 3.8rem;
}

.radioLabel {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    font-family: var(--segoe);
    color: var(--darkBlue);
    font-size: 1.4rem;
    display: flex;
    align-items: center;
    padding-left: 3.5rem;
    border-radius: 0.4rem;
    border: 1px solid var(--grey2);
}

.radioLabel:hover,
.radioWrapper input:hover {
    cursor: pointer;
}

.radioLabel span {
    line-height: 1;
}

.radioWrapper input {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    margin: unset;
    margin-right: 1rem;
    flex: 0;
    width: 20px;
    height: 20px;
    z-index: 1;
}

.radioWrapper input[type="radio"]:checked + .radioLabel {
    border: 1px solid var(--darkBlue);
}
