.card {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 2.4rem;
    background-color: var(--white);
    border-radius: 4px;
    border: 1px solid var(--grey4);
    padding: 2.4rem 1.6rem;
}

.card:hover {
    transition: all 0.3s ease-in-out;
    background-color: var(--green4);
    box-shadow: 0 2px 6px var(--grey2);
}

.link {
    text-decoration: none;
    cursor: pointer;
}

.image-container {
    position: relative;
    width: 100%;
    padding-top: 56.25%; /* 16:9 Aspect Ratio */
}

.image {
    object-fit: cover;
    object-position: center;
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
}

.info {
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1.6rem;
}

.title {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
    height: 4.8rem;
    font-family: --var(segoe);
    font-size: 1.6rem;
    color: var(--darkBlue);
}

.price {
    color: var(--green);
    font-size: 2.4rem;
    font-family: var(--segoe-bold);
    line-height: 1.8rem;
    letter-spacing: 0.12px;
}

@media (max-width: 599px) {
    .card {
        gap: 1.6rem;
        padding: 1.6rem;
    }

    .title {
        height: auto;
        max-height: 4.8rem;
    }
}

@media (max-width: 768px) {
}

@media (max-width: 959px) {
}
