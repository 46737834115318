.infoRow {
    /* margin-top: 1rem; */
    padding: 1rem;
}

.mapRow {
    padding: 1rem 0;
}

.image {
    width: 100%;
    height: 14rem;
    object-fit: contain;
}

.infoText {
    padding: 0 2rem;
}

.reservationBtn {
    background-color: #1fba9c;
    border: 1px solid #1fba9c;
    border-radius: 27px;
    height: 5.4rem;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.mobileBtn {
    display: flex;
    align-items: center;
    cursor: pointer;
}

@media (max-width: 599px) {
    .image {
        height: 7rem;
    }
}
