.Btn,
.BtnWithIcon {
    width: 30rem;
    max-width: 100%;
    font-size: 1.6rem;
    font-family: var(--segoe-bold);
    border-radius: 2.7rem;
    line-height: 5.4rem;
    text-align: center;
    position: relative;
    margin: 0 auto;
}

.Btn:hover,
.BtnWithIcon:hover {
    cursor: pointer;
}

.darkBlue {
    background-color: var(--darkBlue);
    border: 1px solid var(--darkBlue);
    color: #fff;
    border: none;
}
.darkBlue:hover {
    filter: brightness(1.2);
}

.green {
    background-color: var(--green);
    border: 1px solid var(--green);
    color: #fff;
}

.green:hover {
    background-color: #16a287;
}

.yellow {
    background-color: var(--yellow);
    border: none;
    color: var(--darkBlue);
}

.yellow:hover {
    background-color: #f9c80a;
}

.transparentRed {
    background-color: transparent;
    border: 1px solid var(--red);
    color: var(--red);
}

.transparentRed:hover {
    background-color: var(--red);
    border: 1px solid #fff;
    color: #fff;
}

.transparent {
    background-color: transparent;
    border: 1px solid var(--darkBlue);
    color: var(--darkBlue);
}

.transparentGreen {
    background-color: transparent;
    color: var(--green);
    border: 1px solid var(--green);
}

.transparentGreen:hover {
    background-color: var(--green);
    border: 1px solid #fff;
    color: #fff;
}

.selected,
.selected:hover {
    background-color: transparent;
    color: var(--green);
    border: 1px solid var(--green);
    align-items: center;
    display: flex;
    height: 5.4rem;
    justify-content: center;
}

.tick {
    width: 2.3rem;
}

.whiteGreen {
    background-color: #fff;
    border: 1px solid var(--green);
    color: var(--green);
}

@media (max-width: 959px) {
    .Btn,
    .BtnWithIcon {
        line-height: 4rem;
        margin-top: 10px;
        width: 100%;
    }

    .selected {
        height: 4rem;
    }
}
