.item {
    margin-bottom: 2rem;
}

.item label {
    display: block;
    margin-bottom: 1rem;
    font-size: 1.4rem;
    font-family: var(--segoe-semibold);
    color: var(--darkBlue);
}

.itemRow {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 2rem;
}

.itemContent {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;
    width: 100%;
}

.itemContent span {
    display: block;
    margin-left: auto;
    font-family: var(--segoe);
    font-size: 1.2rem;
    color: var(--grey);
    border: 1px solid var(--grey2);
    background-color: var(--grey3);
    padding: 1px 6px;
    max-height: 2rem;
}

.setAsDefaultButton {
    margin-left: auto;
    font-family: var(--segoe);
    font-size: 1.2rem;
    color: var(--linkBlue);
    border: 1px solid var(--grey2);
    background: unset;
}

.setAsDefaultButton:hover {
    cursor: pointer;
    background-color: var(--linkBlue);
    border-color: var(--linkBlue);
    color: #fff;
}

.revoke {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    gap: 0.5rem;
    color: var(--linkBlue) !important;
    font-family: var(--segoe-semibold);
    font-size: 1.6rem;
}

@media (max-width: 599px) {
    .itemRow {
        gap: 1rem;
    }

    .revoke span {
        display: none;
    }
}
