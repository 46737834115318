.cancel {
    color: var(--green);
    font-weight: 300;
    font-family: var(--roboto);
    font-size: 1.4rem;
}

.cancel:hover {
    cursor: pointer;
    text-decoration: underline;
}
