.inner {
    padding-top: 5rem;
    padding-bottom: 5rem;
}

.card {
    padding: 6rem 10rem;
    margin-top: 2rem;
}

.topicsHeader {
    font-size: 3.2rem;
    font-family: var(--segoe);
    color: var(--darkBlue);
    text-transform: uppercase;
    margin-bottom: 3rem;
}

.links li {
    margin-bottom: 2rem;
}

.link {
    font-size: 1.8rem;
    font-family: var(--roboto);
    color: var(--darkBlue);
    text-decoration: none;
}

.link:hover {
    font-weight: 700;
}

.linkActive {
    padding-left: 1rem;
    font-weight: 700;
    border-left: 6px solid var(--green);
    margin-left: -14px;
}

.content {
    padding-top: 3rem;
}

@media (max-width: 959px) {
    .card {
        padding: 2rem 2rem;
    }
}

@media (max-width: 599px) {
    .inner {
        padding-top: 2rem;
    }

    .card {
        padding: 0;
    }

    .topicsHeader {
        font-size: 2.6rem;
    }
}
