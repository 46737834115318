.PhoneInput input {
    background-color: transparent;
    border: none;
    width: 100%;
    height: 100%;
    font-family: var(--roboto);
    color: #263d50;
    font-size: 1.6rem;
}

.PhoneInput input:focus {
    outline: none;
}

.ReactPasswordStrength-input {
    background-color: transparent;
    height: 4rem;
    border: none;
    outline: none;
}

div .ReactPasswordStrength-strength-bar {
    top: 1rem;
}

div .ReactPasswordStrength-strength-desc {
    width: 25%;
    font-size: 1.2rem;
}
