.notification {
    background-color: #ffd943;
    height: 2rem;
    width: 2rem;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.icon {
    width: 1rem;
    height: 1rem;
}
