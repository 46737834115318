.selectText {
    font-size: 1.6rem;
}

.image {
    width: 2rem;
    margin-right: 2rem;
}

.optionContainer {
    display: flex;
    align-items: center;
}
