.uList {
    /* list-style: decimal; */
    padding-left: 3rem;
}

.uList {
    margin-top: 0.5rem;
}

.uList li::marker {
    content: "-   ";
}
