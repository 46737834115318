.Breadcrumbs {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}

.icon {
    margin-left: 1rem;
    margin-right: 1rem;
    margin-bottom: 1rem;

    color: var(--green);
    width: 2.5rem !important;
    height: 2.5rem !important;
}

.slash {
    padding: 0 0.5rem;
}

.link {
    font-size: 1.6rem;
    color: var(--darkBlue);
    font-family: var(--segoe);
    text-decoration: none;
}

.link:first-child {
    font-family: var(--segoe-semibold);
}

.link:hover {
    text-decoration: underline;
}

.element {
    display: flex;
    align-items: center;
}
