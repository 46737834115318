.layout {
    display: grid;
    grid-column-gap: 0.8rem;
}

.authenticatedLayout {
    grid-template-columns: 240px 1fr;
}

.unAuthenticatedLayout {
    grid-template-columns: 1fr;
}

@media (max-width: 959px) {
    .layout {
        grid-template-columns: 1fr;
    }
}

.overflow-scroll {
    overflow-y: scroll;
}
