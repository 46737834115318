.subheader {
    font-size: 1.6rem;
    font-weight: 400;
    font-family: var(--segoe-semibold);
    color: var(--darkBlue);
    line-height: 2.1rem;
    width: 50%;
    text-align: left;
}

@media (max-width: 599px) {
    .subheader {
        font-size: 1.4rem;
        font-family: var(--segoe);
        line-height: normal;
        white-space: normal;
        width: 100%;
        margin-bottom: 2rem;
    }
}
