.container {
    position: relative;
    min-height: 50vh;
    max-height: 100vh;
    overflow-x: hidden;
    overflow-y: scroll;
    z-index: 1;
    scrollbar-color: var(--green) #fff;
}

.container::-webkit-scrollbar {
    width: 0.5rem;
}

.container::-webkit-scrollbar-track {
}

.container::-webkit-scrollbar-thumb {
    background-color: var(--green);
}
