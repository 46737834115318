.innerContainer {
    padding-top: 5rem;
    padding-bottom: 5rem;
    width: 81rem;
    max-width: 100%;
    margin-right: auto;
    margin-left: auto;
}

.cardContainer {
    padding: 5rem 2rem;
}

.tick {
    width: 4rem;
    height: 4rem;
}

.tickContainer {
    width: 7rem;
    height: 7rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 5px solid var(--green);
    border-radius: 50%;
    margin-left: auto;
    margin-right: auto;
}

.body {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 8rem;
}

.bodyIcon {
    width: 5rem;
    height: 5rem;
    margin-bottom: 2rem;
}

.bodyText {
    font-size: 1.6rem;
    color: var(--darkBlue);
    font-family: var(--roboto);
    width: 70%;
    margin: auto;
    text-align: center;
}

.button {
    margin: 2rem auto;
}

.disabledButton {
    margin: 2rem auto;
    opacity: 0.3;
    background-color: grey;
    border: none;
    pointer-events: none;
}

.text {
    font-size: 1.6rem;
    color: var(--darkBlue);
    font-family: var(--roboto);
    margin-bottom: 1rem;
}

.responseTime {
    font-size: 2.4rem;
    color: var(--green);
    font-family: var(--roboto);
    margin-bottom: 2rem;
    font-weight: 700;
}

/* .link {
  color: var(--green);
  margin-top: 2rem;
  font-weight: 700;
  font-size: 3rem;
  display: inline-block;
} */

.questionText {
    text-align: center;
    font-weight: bold;
    font-size: 2.8rem;
    color: var(--darkBlue);
    font-family: var(--segoe);
    margin-top: 6rem;
    margin-bottom: 4rem;
}

.starsWrapper {
    display: flex;
    justify-content: center;
    margin-bottom: 4rem;
}

.checkboxes {
    padding: 2rem;
}

.checkboxLabel {
    font-size: 1.8rem;
}

.link {
    font-family: var(--segoe-semibold);
    color: #11a2ed;
    font-weight: 700;
    text-decoration: none;
}

@media (max-width: 599px) {
    .bodyText {
        width: 100%;
    }

    .cardContainer {
        padding: 0;
    }

    .questionText {
        font-size: 2.2rem;
        margin-top: 4rem;
        margin-bottom: 4rem;
    }

    .checkboxLabel {
        font-size: 1.6rem;
    }
}
