.PersonIntro {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
    margin: unset;
}

.imageContainer {
    width: 100%;
    position: relative;
    height: 15rem;
}

.image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    border-radius: 0.6rem;
    border: 1px solid var(--grey2);
}

.availability {
    position: absolute;
    bottom: 1rem;
    left: 1rem;
    background-color: #929ea7;
    color: #fff;
    font-size: 1.4rem;
    /* line-height: 2rem; */
    padding: 0.5rem 1rem;
    font-family: var(--segoe);
    font-weight: 700;
}

.availability.available {
    background-color: #fff;
    color: var(--green);
}

.name {
    font-size: 1.6rem;
    font-family: var(--segoe-semibold);
    color: var(--darkBlue);
    line-height: 2.1rem;
    margin-top: 1rem;
    margin-bottom: 2rem;
}

.qualification {
    font-size: 1.4rem;
    font-family: var(--segoe);
    color: var(--grey1);
}

.image:hover,
.name:hover,
.qualification:hover {
    cursor: pointer;
}
