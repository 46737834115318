.CookieSettings {
    min-height: calc(100vh - 306px);
}

.CookieSettings {
    padding-top: 5rem;
}

.CookieConsentInner {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.title {
    text-transform: uppercase;
    font-size: 1.8rem;
    font-weight: 700;
    font-family: var(--roboto);
    line-height: 2;
}

.link {
    font-size: 1.6rem;
    line-height: 2;
}

.card {
    padding: 3rem 2rem;
    margin-bottom: 4rem;
}

.checkboxContainer {
    display: flex;
    flex-direction: column;
}

.line {
    background-color: #ccc;
    width: 100%;
    height: 1px;
    margin-top: 2rem;
    margin-bottom: 2rem;
}

.card p {
    font-size: 1.6rem;
    color: var(--darkBlue);
    font-family: var(--roboto);
}

.subtitle {
    font-weight: 700;
    margin-top: 1rem;
    margin-bottom: 1rem;
}
