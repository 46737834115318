.inner {
    padding-top: 5rem;
    padding-bottom: 5rem;
}

.pageHeader {
    margin-top: 0;
    padding-top: 5rem;
}

.topContainer {
    background-color: white;
    /* height: 50rem; */
    width: 100%;
    margin-bottom: 4rem;
    margin-top: 4rem;
}

.card {
    padding: 6rem 6rem;
    margin-top: 2rem;
}

.header {
    font-size: 3.2rem;
    font-family: var(--segoe);
    color: var(--darkBlue);
    text-transform: uppercase;
    margin-bottom: 3rem;
}

.icon {
    width: 7rem;
    height: 7rem;
}

.filterText {
    font-size: 1.6rem;
    font-family: var(--segoe);
    color: var(--darkBlue);
    line-height: 2rem;
}

.input {
    width: 25rem;
}

.text {
    font-size: 1.6rem;
    font-family: var(--segoe);
    color: #11a2ed;
    font-weight: bold;
    cursor: pointer;
    margin-top: 2rem;
    margin-bottom: 2rem;
    text-align: center;
}

.noTAJ {
    margin-top: 1rem;
    color: var(--linkBlue);
    font-size: 1.4rem;
    font-weight: 700;
    display: block;
}

.noTAJ:hover {
    cursor: pointer;
    text-decoration: underline;
}

.notVerified {
    font-size: 1.6rem;
    color: #11a2ed;
    font-family: var(--segoe);
    font-weight: 700;
}

.notVerified:hover {
    cursor: pointer;
}

@media (max-width: 959px) {
    .card {
        padding: 2rem 2rem;
    }
}

@media (max-width: 599px) {
    .inner {
        padding-top: 2rem;
    }

    .card {
        padding: 0;
    }

    .icon {
        margin-bottom: 1rem;
    }

    .addressTitle {
        margin-bottom: 1rem !important;
    }
}
