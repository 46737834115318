.button {
    background-color: var(--green);
    color: #fff;
    font-family: var(--segoe);
    font-size: 1.2rem;
    line-height: normal;
    width: 9rem;
    max-width: 90%;
    height: 3rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 1.6rem;
    margin-bottom: 0.5rem;
    margin-top: 0.5rem;
    transition: all 0.1s ease;
}

.humed.button {
    background-color: var(--humedDark);
}

.toggleButton {
    color: var(--green);
    background-color: #fff;
    border-color: var(--green);
    border-width: 1px;
    border-style: solid;
}

.humed.toggleButton {
    background-color: #fff;
    border-width: 1px;
    border-style: solid;
    color: var(--humedDark);
    border-color: var(--humedDark);
}

.hidden {
    display: none;
}

.button:hover {
    cursor: pointer;
    transform: scale(1.05);
}
