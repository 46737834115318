.intro {
    background-color: var(--grey3);
    padding-top: 3rem;
    padding-bottom: 3.6rem;
}

.PageSubHeader {
    margin-bottom: 2rem;
}

.text {
    font-size: 1.6rem;
    font-family: var(--segoe);
    line-height: 2.1rem;
    color: var(--darkBlue);
    margin-bottom: 4rem;
}

.text strong {
    font-family: var(--segoe-semibold);
}

.list li {
    display: flex;
    padding-bottom: 1rem;
    padding-top: 1rem;
    border-bottom: 1px solid #eee;
    justify-content: space-between;
}

.list li span {
    font-size: 1.6rem;
    font-family: var(--segoe);
    color: var(--darkBlue);
    line-height: 3rem;
}

.left {
    font-weight: 300;
}

.right {
    font-weight: 700;
    text-align: right;
}

.normal {
    font-weight: 400;
}

.info {
    font-size: 1.4rem;
    font-family: var(--roboto);
    color: var(--darkBlue);
    margin-top: 2rem;
}

.title {
    font-size: 2rem;
    font-weight: 700;
    font-family: var(--segoe);
    text-transform: uppercase;
    color: var(--darkBlue);
    margin-bottom: 6rem;
    margin-top: 3rem;
    text-align: center;
}

.strong {
    font-weight: 700;
}

@media (max-width: 959px) {
    .subscriptionCardGrid {
        margin-bottom: 3rem !important;
    }
    .inner {
        padding-top: 2rem;
    }
}
