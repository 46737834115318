.inner {
    padding-top: 5rem;
    padding-bottom: 5rem;
}

.inner p {
    font-family: var(--roboto);
    font-size: 1.6rem;
    color: var(--darkBlue);
    line-height: 2.4rem;
    margin-bottom: 1rem;
}

.blackText,
.blackText a {
    font-family: var(--roboto);
    font-size: 1.6rem;
    color: var(--darkBlue);
    line-height: 2.4rem;
    margin-bottom: 1rem;
    text-decoration: none;
}

.card {
    padding: 6rem 10rem;
    margin-top: 2rem;
}

.title {
    font-size: 3.6rem;
    font-family: var(--segoe);
    color: var(--darkBlue);
    text-transform: uppercase;
    margin-bottom: 4rem;
}

.greenText {
    font-family: var(--roboto);
    font-size: 2.4rem;
    color: var(--green);
    font-weight: 700;
    line-height: 2.4rem;
    margin-top: 5rem;
    margin-bottom: 2rem;
}

.link {
    font-family: var(--roboto);
    font-size: 1.6rem;
    font-weight: 700;
    color: #11a2ed;
    line-height: 2.4rem;
    text-decoration: underline;
}

.subheader {
    font-weight: 700;
    margin-top: 2rem;
}

ol.list {
    list-style: decimal;
    padding-left: 3rem;
}

.list li {
    margin-bottom: 1rem;
}

.button {
    margin-top: 4rem;
}

.strong {
    font-weight: 700;
}

.navigation li a:hover {
    cursor: pointer;
    text-decoration: underline;
}

@media (max-width: 959px) {
    .card {
        padding: 2rem 2rem;
    }
}

@media (max-width: 599px) {
    .inner {
        padding-top: 2rem;
    }

    .title {
        font-size: 2.6rem;
        font-weight: 400;
        margin-bottom: 3rem;
    }

    .blackText,
    .greenText {
        margin-bottom: 3rem;
    }

    .card {
        padding: 0;
    }

    .button {
        width: 100%;
    }

    .popularQuestionsHeaderRow {
        flex-direction: column;
        align-items: flex-start;
        margin-bottom: 3rem;
    }

    .popularQuestionsHeader {
        margin-bottom: 1rem;
    }

    .popularText {
        margin-bottom: 1rem;
    }

    .popularQuestion {
        margin-bottom: 1rem;
    }
}
