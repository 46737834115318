.RegistrationModal {
    width: 70rem;
    outline: none;
}

.RegistrationModalInner {
    padding: 3rem 8rem 3rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    outline: none;
}

.headerRow {
    display: flex;
    flex-grow: 1;
    width: 100%;
    align-items: center;
}

.header {
    font-size: 3rem;
    font-family: var(--segoe);
    font-family: 700;
    color: #7e9591;
    text-transform: uppercase;
    margin: 0 2rem;
    font-weight: 700;
    text-align: center;
}

.line {
    flex-grow: 1;
    height: 1px;
    background-color: #7e9591;
}

.inputContainer {
    width: 100%;
    margin-top: 2.5rem;
}

.input {
    width: 100%;
    border-radius: 4px;
}

.label {
    font-size: 1.4rem;
    font-family: var(--segoe);
    color: #7e9591;
    text-transform: uppercase;
    margin-bottom: 1rem;
}

div .checkboxLabel {
    font-size: 1.6rem;
    font-family: var(--roboto);
    font-weight: 300;
    color: #263d50;
}

.button {
    width: 100%;
    margin-top: 3rem;
}

.smaller {
    margin-top: 4rem;
    font-weight: 400;
}

.smaller .header {
    font-size: 1.6rem;
}

.linkRow {
    text-align: center;
    margin-top: 2rem;
}

.link {
    font-family: var(--segoe-semibold);
    color: #11a2ed;
    font-size: 1.6rem;
    text-decoration: none;
}

.greenLink {
    color: var(--green);
}

.loginText {
    font-family: var(--segoe-semibold);
    color: #263d50;
    font-size: 1.4rem;
}

.link:hover {
    cursor: pointer;
}

.counter {
    color: #263d50;
    font-size: 1.4rem;
    font-family: var(--segoe);
    font-weight: 700;
    text-align: center;
    margin-top: 2rem;
    margin-bottom: 2rem;
}

.infoText {
    color: #263d50;
    font-size: 1.6rem;
    font-family: var(--segoe);
    text-align: center;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    line-height: 2rem;
}

.infoText .link {
    font-size: 1.6rem;
}

.circle {
    border: 4px solid var(--green);
    width: 8rem;
    height: 8rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 3rem;
    margin-bottom: 3rem;
}

.checkboxIcon {
    font-size: 5rem;
    color: var(--green);
}

.registeredEmail {
    color: #1fba9c;
    font-family: var(--segoe-semibold);
    font-size: 2rem;
    margin-top: 1rem;
    margin-bottom: 7rem;
    text-align: center;
    word-break: break-all;
}

@media (max-width: 959px) {
    .RegistrationModal {
        width: 40rem;
    }

    .RegistrationModalInner {
        padding: 1rem;
    }

    .header {
        font-size: 2rem;
    }
}

@media (max-width: 550px) {
    .RegistrationModal {
        width: auto;
    }
}
