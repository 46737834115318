.inner {
    padding-top: 5rem;
    padding-bottom: 5rem;
}

.AlertLine {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
}

.cardContainer {
    width: 25%;
    margin-bottom: 6rem;
}

.institutionsContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    margin-top: 3rem;
}

.noMatch {
    font-size: 1.9rem;
    font-family: var(--segoe);
    color: var(--darkBlue);
    line-height: 2.3rem;
    text-transform: uppercase;
}

@media (max-width: 959px) {
    .cardContainer {
        width: 33.333%;
    }
}

@media (max-width: 699px) {
    .cardContainer {
        width: 50%;
    }
}

@media (max-width: 599px) {
    .AlertLine {
        width: auto;
        max-width: none;
        margin-left: -16px;
        margin-right: -16px;
    }

    .inner {
        padding-top: 2rem;
    }
}
