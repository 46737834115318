.inputContainer {
    display: flex;
    align-items: center;
    padding: 1rem 2rem 1rem;
    border: 1px solid #929ea7 !important;
    border-radius: 6px;
    background: #f2fbf9;
    margin-top: 3.4rem;
    margin-right: 2rem;
    width: 100%;
    height: 54px;
}

.searchIcon {
    width: 20px;
    height: 20px;
    margin-right: 2rem;
}

.searchInput {
    flex: 1;
    font-size: 1.6rem;
    outline: none !important;
    background: transparent;
    border: none;
    width: 40rem;
}

/* :root {
  --reach-combobox: 1;
} */

[data-reach-combobox-popover] {
    border: 1px solid #929ea7 !important;
    background: hsla(0, 100%, 100%, 0.99);
    font-size: 85%;
    z-index: 20;
}

[data-reach-combobox-list] {
    font-size: 1.6rem;
    list-style: none;
    margin: 0;
    padding: 0;
    user-select: none;
}

[data-reach-combobox-option] {
    cursor: pointer;
    margin: 0;
    padding: 0.5rem;
}

[data-reach-combobox-option][aria-selected="true"] {
    background: hsl(211, 10%, 95%);
}

[data-reach-combobox-option]:hover {
    background: hsl(211, 10%, 92%);
}

[data-reach-combobox-option][aria-selected="true"]:hover {
    background: hsl(211, 10%, 90%);
}

[data-suggested-value] {
    font-weight: bold;
}

@media (max-width: 1279px) {
    .searchInput {
        width: 30rem;
    }
}

@media (max-width: 959px) {
    .searchInput {
        width: 45rem;
    }

    .inputContainer {
        margin-top: 1rem;
    }
}

@media (max-width: 599px) {
    .searchInput {
        width: 30rem;
    }
}

@media (max-width: 399px) {
    .searchInput {
        width: 20rem;
    }
}
