.paperSection {
    padding: 2rem 4rem;
    border-left: 0.5rem solid var(--grey4);
}

.paperSection.opened {
    border-left: 0.5rem solid var(--mercury);
}

.appointment {
    display: flex;
    flex-direction: column;
    gap: 4rem;
}

.appointmentData {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 2rem;
}

.appointmentHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 2rem;
}

.name {
    font-family: var(--segoe-semibold);
    color: var(--darkBlue);
    font-size: 1.6rem;
    line-height: 2.1rem;
}

.appointmentRow {
    display: flex;
    justify-content: space-between;
    gap: 2rem;
}

.appointmentRow > span {
    background-color: var(--grey4);
    display: block;
    width: 1px;
    height: auto;
}

.appointmentCol {
    flex: 1;
}

.appointmentInfo {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 2rem;
}

.appointmentInfo p {
    font-size: 1.4rem;
    color: var(--darkBlue);
}

.appointmentInfoData {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 2rem;
    flex: 1;
}

.appointmentId {
    font-size: 1.4rem;
    color: var(--grey1);
}

.appointmentId,
.openDetails {
    margin-left: auto;
}

.openDetails {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;
    cursor: pointer;
}

.openDetails span {
    font-family: var(--segoe-semibold);
    color: var(--linkBlue);
}

.openDetailsIcon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 2.6rem;
    height: 2.6rem;
    border: 1px solid var(--linkBlue);
    border-radius: 0.4rem;
}

.openDetailsIcon > svg {
    fill: var(--linkBlue);
}

.documents {
    width: 100%;
    flex: 1 1 100%;
    height: 100%;
    display: none;
}

.documents + .link {
    display: none !important;
}

.document_count {
    display: none !important;
}

.documents ul {
    width: 100%;
    height: 100%;
    max-height: 20rem;
    display: flex;
    flex-direction: column;
    background-color: var(--grey3);
    border: 1px solid var(--grey2);
    overflow-x: hidden;
    overflow-y: scroll;
    scrollbar-color: var(--grey1) var(--grey2);
}

.documents ul::-webkit-scrollbar {
    width: 1rem;
}

.documents ul::-webkit-scrollbar-track {
    background-color: var(--grey2);
}

.documents ul::-webkit-scrollbar-thumb {
    border-radius: 10rem;
    background-color: var(--grey1);
}

.documents ul li {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid var(--grey2);
}

.documents ul li span {
    flex: 1;
    padding: 1.2rem 1rem;
    cursor: pointer;
    font-size: 1.4rem;
}

.documents ul li button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 4rem;
    height: 4rem;
    padding: 0;
    background-color: unset;
    border: unset;
    cursor: pointer;
}

.documents ul li:last-child {
    border-bottom: unset;
}

.details {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    color: var(--darkBlue);
    height: 100%;
}

.details dl {
    flex: 1;
}

.details dl dt {
    font-size: 1.6rem;
    font-family: var(--segoe-semibold);
    margin-bottom: 1rem;
}

.details dl dd {
    margin-bottom: 2rem;
}

.details dl dd ul {
    margin-left: 2rem;
    list-style: disc;
}

.details dl dd ul li {
    margin-bottom: 2rem;
}

.link {
    display: inline !important;
    color: var(--linkBlue) !important;
    font-family: var(--segoe-semibold);
    font-size: 1.6rem;
    margin-top: auto !important;
}

.link:disabled {
    color: var(--grey1) !important;
    pointer-events: none;
    cursor: not-allowed;
}

@media (max-width: 959px) {
}

@media (max-width: 768px) {
    .paperSection {
        padding: 1.6rem 1rem;
        border-left: 0.5rem solid var(--grey4);
    }

    .appointmentRow {
        flex-direction: column;
    }

    .appointmentHeader {
        position: relative;
        align-items: flex-start;
        gap: 1rem;
    }

    .openDetails {
        position: absolute;
        right: 0;
        top: 0;
    }

    .appointmentData {
        gap: 1rem;
    }

    .appointmentInfo,
    .appointmentInfoData {
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 1rem;
    }

    .appointmentInfoData {
        flex-direction: column-reverse;
    }

    .appointmentId {
        margin-left: unset;
    }

    .openDetails span {
        display: none;
    }
}

@media (max-width: 599px) {
}
