.FooterPaymentInner {
    display: flex;
    align-items: center;
    list-style: none;
    margin: 0;
    padding-top: 2rem;
    flex-wrap: wrap;
    position: relative;
}

.FooterPaymentImage {
    height: 4rem;
}

.FooterPaymentImageSmaller {
    height: 2.3rem;
}

.icons {
    display: flex;
    /* flex: 1; */
    justify-content: space-between;
    align-items: center;
    margin-top: 2rem;
    margin-bottom: 2rem;
    flex-wrap: wrap;
}

@media (max-width: 599px) {
    .FooterPaymentImage {
        height: 3rem;
    }

    .FooterPaymentImageSmaller {
        height: 2rem;
    }

    .icons {
        flex-grow: 1;
        justify-content: flex-start;
    }

    .FooterPaymentImage,
    .FooterPaymentImageSmaller {
        margin-right: 2rem;
        margin-bottom: 2rem;
    }
}

@media (max-width: 500px) {
    .linebreak {
        width: 100%;
    }
}
