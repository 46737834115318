.pageHeader {
    margin-top: 0;
    padding-top: 5rem;
}

.topContainer {
    background-color: white;
    /* height: 50rem; */
    width: 100%;
    margin-bottom: 4rem;
    margin-top: 4rem;
}

.title {
    font-family: var(--segoe);
    color: var(--darkBlue);
    font-size: 2rem;
    font-weight: bold;
    margin-top: 2rem;
    margin-bottom: 2rem;
}

.cardText {
    font-size: 1.8rem;
    font-family: var(--roboto);
    font-weight: normal;
    color: #263d50;
    line-height: 2.6rem;
    /* margin-bottom: 2rem; */
}

.titleContainer {
    display: flex;
    align-items: center;
}

.linkText {
    font-size: 1.8rem;
    font-family: var(--segoe);
    color: #11a2ed;
    font-weight: bold;
    cursor: pointer;
    /* margin-top: 2rem;
  margin-bottom: 2rem; */
}

.redeemBtn {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--green);
    border-radius: 27px;
    width: 18rem;
    height: 5.4rem;
}

.redeemText {
    font-size: 1.6rem;
    font-family: var(--segoe);
    color: white;
    cursor: pointer;
}

.icon {
    width: 2rem;
    height: 2rem;
}

.cancelBtn {
    font-size: 1.6rem;
    font-family: var(--segoe);
    font-weight: bold;
    cursor: pointer;
}

/* span {
  font-size: 1.8rem;
  font-family: var(--roboto);
  font-weight: normal;
  color: #263D50;
} */

.list li {
    display: flex;
    padding-bottom: 1rem;
    padding-top: 1rem;
    border-bottom: 1px solid #eee;
    justify-content: space-between;
}

.list li span {
    font-size: 1.6rem;
    font-family: var(--segoe);
    color: var(--darkBlue);
    line-height: 3rem;
}

.left {
    font-weight: 300;
}

.right {
    font-weight: 700;
    text-align: right;
}

.normal {
    font-weight: 400;
}

.info {
    font-size: 1.4rem;
    font-family: var(--roboto);
    color: var(--darkBlue);
    margin-top: 2rem;
}

/* .input {
  width: 25rem;
} */

.inner {
    padding-top: 5rem;
    padding-bottom: 5rem;
}

.card {
    padding: 6rem 6rem;
    margin-top: 2rem;
}

.header {
    font-size: 3.2rem;
    font-family: var(--segoe);
    color: var(--darkBlue);
    text-transform: uppercase;
    margin-bottom: 3rem;
}

.mainIcon {
    width: 7rem;
    height: 7rem;
}

@media (max-width: 959px) {
    .card {
        padding: 2rem 2rem;
    }
}

@media (max-width: 599px) {
    .inner {
        padding-top: 2rem;
    }

    .card {
        padding: 0;
    }

    .mainIcon {
        margin-bottom: 1rem;
    }

    .infoWrapper {
        margin-top: 2rem;
    }
}
