.input {
    position: relative;
    background-color: #fff;
    border: 1px solid var(--grey2);
    border-radius: 0.4rem;
    width: 100%;
    max-width: 100%;
    height: 3.8rem;
    padding: 1rem 1.2rem;
}

.input.bigger {
    height: 5rem;
    border-radius: 6px;
    padding: 1.4rem 1.8rem;
    font-size: 1.6rem;
}

.wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
}

.wrapper .icon {
    position: absolute;
    z-index: 1;
    top: 50%;
    transform: translateY(-50%);
    right: 1rem;
    color: var(--linkBlue) !important;
}

.visibility:hover {
    cursor: pointer;
}
