.badge {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    padding: 0.5rem 1rem;
    max-height: 2.8rem;
    background-color: var(--green);
    font-family: var(--segoe);
    color: #fff;
    font-size: 1.4rem;
    line-height: 1;
    border-radius: 0.3rem;
    text-transform: capitalize;
    border-radius: 100rem;
}

.badgeIcon {
    margin-right: 1.6rem;
}

.ok {
    border: 1px solid var(--green1);
    background-color: var(--green2);
    color: var(--green);
    font-size: 1.2rem;

    font-family: var(--segoe);
}

.warning {
    border: 1px solid var(--deepLemon1);
    background-color: var(--deepLemon2);
    color: var(--deepLemon);
    font-size: 1.2rem;
    font-family: var(--segoe);
}

.alert {
    border: 1px solid var(--amaranth1);
    background-color: var(--amaranth2);
    color: var(--amaranth);
    font-size: 1.2rem;
    font-family: var(--segoe);
}

.info {
    border: 1px solid var(--grey2);
    background-color: var(--grey3);
    color: var(--darkBlue);
    font-size: 1.2rem;
    font-family: var(--segoe);
}
