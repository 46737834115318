.selectBtn {
    /* background-color: #929EA7; */
    width: 100%;
    border-radius: 2.7rem;
    height: 5.4rem;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.selectBtn span {
    /* color: white; */
    font-size: 1.6rem;
    font-family: var(--segoe);
}
