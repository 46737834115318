.tick {
    display: inline-block;
    border-radius: 50%;
    border: 4px solid var(--green);
    margin-bottom: 3rem;
}

.tick.humed {
    border-color: var(--humedDark);
}

.tick svg {
    height: 4.1rem;
    width: 4.8rem;
    margin: 2rem;
    fill: var(--green);
}

.tick.humed svg {
    fill: var(--humedDark);
}

.text {
    text-align: center;
    margin-bottom: 3rem;
    font-size: 1.8rem;
    color: var(--green);
    font-weight: bold;
    font-family: var(--segoe);
}

.btnWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.charInfo {
    text-align: end;
    padding-top: 1rem;
    color: var(--grey);
    margin-bottom: 1rem;
}

.textLabel {
    margin-bottom: 1rem;
}
