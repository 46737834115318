.container {
    position: absolute;
    bottom: 0rem;
    left: 0rem;
    z-index: 10;
    margin: 0;
    padding: 1rem;
    width: 100%;
    color: white;
    background: RGBA(38, 61, 80, 0.85);
}

.searchContainer {
    position: absolute;

    /* top: 0rem; */
    /* left: 0rem; */
    z-index: 10;
    padding: 1rem;
    width: 100%;
    display: flex;
    justify-content: center;
    pointer-events: none;
}

.searchBtn {
    width: 24rem;
    height: 4rem;
    background-color: white;
    /* border: 1px solid red; */
    border-radius: 10rem;
    box-shadow: 0 2px 5px -1px rgb(0 0 0 / 30%);
    cursor: pointer;
    display: flex;
    pointer-events: all;
}

.searchIcon {
    width: 2rem;
    height: 2rem;
    margin: 1rem;
}

@media (max-width: 599px) {
    .container {
        background: RGBA(31, 186, 156, 0.85);
    }
}
