.tabs {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    list-style: none;
    padding: 0 2rem;
    margin: 0;
    border-bottom: 1px solid var(--grey2);
    background-color: #fff;
}

.tabs .tab {
    padding: 2.4rem 2rem;
    cursor: pointer;
    color: var(--darkBlue);
}

.tabs .tab.active {
    padding: 2.4rem 2rem 1.9rem;
    border-bottom: 5px solid var(--green);
    background-color: var(--green4);
    color: var(--green);
    font-family: var(--segoe-semibold);
}

@media (max-width: 599px) {
    .tabs {
        padding: 0 0.5rem;
        width: 100%;
        overflow-x: scroll;
        overflow-y: hidden;
    }

    .tabs::-webkit-scrollbar-track,
    .tabs::-webkit-scrollbar-thumb,
    .tabs::-webkit-scrollbar {
        width: 0;
        height: 0;
    }

    .tabs .tab {
        padding: 1.9rem 1.1rem;
        font-size: 1.4rem;
    }

    .tabs .tab.active {
        padding: 1.9rem 1.1rem 1.4rem;
    }
}
