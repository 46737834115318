/*GENERAL*/
p {
    font-size: 1.6rem;
}

.hero {
    background-image: url("../../assets/images/original/landing3_hero.jpg");
    background-position: right;
    /* height: 100%; */
    /* background-size: cover;
    / */
    /* height: 800px; */
    /* background-position: right;
    background-repeat: no-repeat;  */
}

.heroBox {
    width: 100%;
    /* background-color: rgba(255, 255, 255, 0.8); */
    /* padding: 5.3rem 6rem 2.5rem; */
    position: relative;
    border-radius: 1.2rem;
    display: flex;
    justify-content: space-between;
    margin-top: 4rem;
    margin-bottom: 8rem;
}

.heroBoxElement {
    background-color: rgba(255, 255, 255, 0.8);
    border-radius: 1.2rem;
    padding: 2rem 1rem;
    width: 32%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.heroBoxElement p {
    margin: 0;
}

.heroBoxElement .button {
    margin: 0;
}

.heroBox .extraText {
    position: absolute;
    top: -2rem;
    left: -2.1rem;
    background-color: var(--yellow);
    color: var(--darkBlue);
    font-family: var(--roboto);
    font-size: 1.6rem;
    text-transform: uppercase;
    font-weight: 700;
}

.heroBox .extraText span {
    position: relative;
    display: block;
    line-height: 4rem;
    padding: 0 2.5rem;
}

.heroBox .extraText .triangle {
    width: 1px;
    height: 1px;
    border: 1.5rem solid transparent;
    transform: rotateZ(45deg);
    position: absolute;
    left: 0.6rem;
    bottom: -1.5rem;
    border-bottom-color: #e9c944;
}

.heroBox .icon {
    width: 6rem;
    /* margin-bottom: 1.5rem; */
}

.heroBox .header {
    font-size: 3.6rem;
    font-family: var(--segoe-bold);
    color: var(--darkBlue);
    /* text-transform: uppercase; */
    /* margin-bottom: 1.6rem; */
}

.heroBox .text {
    font-size: 1.6rem;
    font-family: var(--roboto);
    color: var(--darkBlue);
    line-height: normal;
    margin-bottom: 2.8rem;
}

.boxElementWrapper {
    margin-left: 2rem;
}

.hero h1 {
    font-size: 4.8rem;
    font-family: var(--segoe-bold);
    /* font-weight: 700; */
    /* color: var(--darkBlue); */
    color: var(--darkBlue);
    margin-top: 8rem;
}

.hero h2 {
    font-size: 4.4rem;
    font-family: var(--segoe);
    /* font-weight: 700; */
    /* color: var(--darkBlue); */
    color: var(--darkBlue);
    margin-top: 5rem;
}

.commercial {
    background-color: var(--green);
    padding: 6rem 0;
}

.commercial h2,
.commercial h3,
.commercial p,
.commercial a {
    color: #fff;
}

.commercial h2 {
    font-size: 4.8rem;
    font-family: var(--segoe-bold);
    margin-bottom: 2rem;
}

.commercial h3 {
    font-size: 2rem;
    margin-bottom: 4rem;
    font-family: var(--roboto);
}

.commercial p {
    font-family: var(--roboto);
}

.commercial {
    text-align: center;
}

.commercial .button {
    margin-bottom: 3rem;
}

.services {
    padding-bottom: 18rem;
}

.list {
    margin-bottom: 3rem;
}

.list li {
    display: flex;
    padding-bottom: 1rem;
    padding-top: 1rem;
    border-bottom: 1px solid #eee;
    justify-content: space-between;
}

.list li span {
    font-size: 1.6rem;
    font-family: var(--segoe);
    color: var(--darkBlue);
    line-height: 3rem;
}

.left {
    font-weight: 300;
}

.right {
    font-weight: 700;
    text-align: right;
}

.subscriptions,
.doctors,
.testimonial {
    padding-bottom: 12rem;
}

.doctors,
.testimonial,
.institutions,
.newsletter {
    background-color: #fff;
    padding-top: 1rem;
}

.navigationItemContainer {
    height: 100%;
    display: flex;
    align-items: center;
}

.navigationItem {
    width: 5.4rem;
    height: 5.4rem;
    border: 1px solid #7e9591;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.navigationItem:hover {
    cursor: pointer;
}

.inputContainer {
    width: 100%;
    margin-top: 2.5rem;
}

.inputContainer .input {
    width: 100%;
    border-radius: 4px;
}

.inputContainer .label {
    font-size: 1.4rem;
    font-family: var(--segoe);
    color: #7e9591;
    text-transform: uppercase;
    margin-bottom: 1rem;
}

.link {
    color: var(--linkBlue);
    text-decoration: none;
    font-weight: 700;
}

.newsletter {
    padding-bottom: 8rem;
}

.heroBtn .button {
    margin: 5rem 0 5rem 0;
}

.infoWrapper {
    /* height: 10rem;  */
}

.infoRow {
    height: 100%;
    position: relative;
    padding: 2rem 0rem;
    border-left: 2px solid var(--green);
}

.infoRowWithHalfBorder {
    height: 100%;
    position: relative;
    padding: 2rem 0rem;
}

.infoRowWithHalfBorder::after {
    content: "";
    background: var(--green);
    position: absolute;
    bottom: 0;
    left: 0;
    height: 50%;
    width: 2px;
    border-collapse: collapse;
}

.infoTitle {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    /* margin-top: 3rem;
    margin-bottom: 6rem; */
    position: relative;
}

.infoTitle .line {
    flex-grow: 1;
    height: 2px;
    background-color: var(--green);
}

.infoTitleWrapper {
    display: flex;
    flex-direction: column;
    flex-grow: 3;
}

.infoTitle .text {
    font-size: 2.6rem;
    font-family: var(--segoe);
    font-weight: bold;
    color: var(--darkBlue);
    margin-left: 3rem;
    /* margin-right: 3rem; */
    /* line-height: 4.8rem; */
    white-space: nowrap;
    margin-bottom: 1rem;
}

.infoTitle .otherText {
    margin-left: 3rem;
}

.image {
    width: 100%;
    /* min-width: 523px; */
    height: 248px;
    object-fit: cover;
    /* border-radius: 1.2rem; */
}

.video-responsive {
    overflow: hidden;
    padding-bottom: 56.25%;
    position: relative;
    height: 0;
    /* padding-bottom: 6rem; */
}

.video-responsive iframe {
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    position: absolute;
}

.processBox {
    height: 100%;
    width: 100%;
    border-radius: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.processBoxInner {
    height: 85%;
    width: 85%;
    background-color: #fff;
    border-radius: 18px;
}

/* .processWrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
} */

.processIcon {
    width: 8rem;
    height: 8rem;
    margin: 4rem 2rem;
}

.processText {
    font-size: 2rem;
    font-family: var(--segoe);
    font-weight: bold;
    color: var(--darkBlue);
    text-transform: uppercase;
    /* white-space: nowrap; */
    text-align: left;
    margin: 0 2rem;
    overflow-wrap: break-word;
}

.processIndicatorWrapper {
    width: 100%;
    display: flex;
    align-items: center;
    height: 20rem;
}

.processIndicatorWrapper .line {
    height: 1rem;
    /* background-color: red; */
    flex-grow: 1;
}

.processIndicatorWrapper .outerBox {
    width: 4.8rem;
    height: 4.8rem;
    border-radius: 50%;
    /* background-color: red; */
    display: flex;
    justify-content: center;
    align-items: center;
}

.processIndicatorWrapper .innerBox {
    width: 2.7rem;
    height: 2.7rem;
    border-radius: 50%;
    background-color: #fff;
}

@media only screen and (max-width: 959px) {
    .hero {
        background-size: cover;
        background-repeat: no-repeat;
        background-position: right;
        background-image: url("../../assets/images/original/landing3_hero_mobile.jpg");
    }

    .hero h1 {
        margin-top: 55rem;
        text-align: center;
        font-size: 3.4rem;
        font-family: var(--segoe-bold);
        color: #fff;
        margin-bottom: 4rem;
        line-height: normal;
    }

    .hero h2 {
        font-size: 3.4rem;
        text-align: center;
        color: #fff;
    }

    .heroBtn .button {
        margin: 5rem auto;
    }

    .heroBox {
        margin-top: 0;
    }

    .heroBox .extraText {
        left: -1rem;
    }

    .heroBox {
        flex-direction: column;
        justify-content: center;
        padding: 0;
        /* padding-top: 5.2rem; */
        margin-bottom: 1.2rem;
    }

    .heroBoxElement {
        width: 100%;
        display: flex;
        /* flex-wrap: wrap; */
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: 1.2rem 0;
        border-radius: 0px;
        box-shadow: 0 10px 20px #0000001a;
    }

    .boxElementWrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: 0;
    }

    .heroBoxElement .text {
        width: 100%;
        margin-top: 1.5rem;
        margin-bottom: 0;
    }

    .heroBox .icon {
        width: 5.2rem;
        margin-bottom: 0;
        /* margin-right: 2rem; */
    }

    .heroBox .header {
        margin-bottom: 0;
    }

    .heroBoxElement .button {
        width: 100%;
        max-width: 50rem;
        margin: 2rem auto 5rem;
        line-height: 4.4rem;
    }

    .commercial h2 {
        font-size: 3.4rem;
        line-height: normal;
    }

    .commercial p {
        font-size: 1.6rem;
    }

    .subscriptions .button {
        align-self: center;
        /* width: 20rem; */
        margin: 0;
        line-height: 4rem;
    }

    .subscriptions .card {
        margin-bottom: 3rem;
    }

    .doctors .image,
    .dutyFinder .image {
        margin-bottom: 3rem;
    }

    .doctors .lightText,
    .dutyFinder .lightText {
        font-size: 1.4rem;
        margin-bottom: 0;
    }

    .doctors .button,
    .dutyFinder .button,
    .institutions .button,
    .newsletter .button {
        margin-left: auto;
        margin-right: auto;
        margin-top: 3rem;
        width: 100%;
        max-width: 50rem;
    }

    .newsletter .button {
        margin-top: 0;
    }

    .navigationItemContainer {
        margin: 0 2rem;
    }

    .benefits,
    .services,
    .subscriptions,
    .dutyFinder {
        padding-top: 0.1rem;
        padding-bottom: 12rem;
        background-color: #f8f8f8;
    }

    .benefits .button {
        margin-bottom: 0;
    }

    .mobileImage {
        width: 100%;
        height: 400px;
        object-fit: cover;
    }

    .verticalLineWrapper {
        display: flex;
        justify-content: center;
    }

    .verticalLine {
        border-left: 2px solid var(--green);
    }

    .coloredBG {
        background: linear-gradient(rgb(60, 176, 153, 1), rgb(54, 164, 201, 1));
    }
}

@media screen and (max-width: 599px) {
    .hero {
        background-image: url("../../assets/images/original/landing3_hero_mobile.jpg");
        background-size: cover;
        background-position: center;
        /* width: 100%; */
        /* height: 400px; */
        /* background-position: 85% 0%;
        background-repeat: no-repeat;        */
    }

    .hero h1 {
        margin-top: 40rem;
        font-size: 2.4rem;
        margin-bottom: 0;
    }

    .hero h2 {
        font-size: 2.4rem;
        margin: 0.5rem;
    }

    .heroBtn .button {
        margin: 4rem auto;
    }

    .heroBoxElement .icon {
        width: 4rem;
    }

    .heroBoxElement .header {
        font-size: 3rem;
    }

    .heroBoxElement p {
        font-size: 1.4rem;
    }

    .mobileImage {
        height: 20rem;
    }

    /* .hero h1 {
        margin-top: 55rem;
        text-align: center;
        font-size: 3.4rem;
        font-family: var(--segoe-bold);
        color: #fff;
        margin-bottom: 4rem;
        line-height: normal;
    } */

    .commercial {
        padding: 3rem 0;
    }

    .commercial h2 {
        font-size: 2.8rem;
    }

    .commercial p {
        font-size: 1.4rem;
    }

    .processIcon {
        width: 6rem;
        height: 6rem;
    }

    .subscriptions {
        padding-bottom: 6rem;
    }
}
