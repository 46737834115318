.title {
    font-family: var(--segoe);
    color: var(--darkBlue);
    font-size: 1.8rem;
    font-weight: bold;
    margin-top: 2rem;
    margin-bottom: 2rem;
}

.cardText {
    font-size: 1.8rem;
    font-family: var(--roboto);
    font-weight: normal;
    color: #263d50;
    /* margin-bottom: 2rem; */
}

.text {
    font-size: 1.6rem;
    font-family: var(--segoe);
    color: #11a2ed;
    font-weight: bold;
    cursor: pointer;
    margin-top: 2rem;
    margin-bottom: 2rem;
}

.icon {
    width: 4rem;
    height: 4rem;
}
