.button {
    width: 30rem;
    height: 5.4rem;
    background-color: var(--green);
    border-radius: 27px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 1.5rem 0;
    cursor: pointer;
}

.button.humed {
    background-color: var(--humedDark);
}

.button span {
    font-size: 1.6rem;
    color: #fff;
    font-weight: bold;
    font-family: var(--sagoe);
    margin-left: 10rem;
}

.arrowIcon {
    color: #fff;
    width: 3rem !important;
    height: 3rem !important;
    margin-right: 1rem;
}
