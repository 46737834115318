.title {
    font-weight: 700;
    font-family: var(--roboto);
    font-size: 2.6rem;
    color: var(--darkBlue);
    margin-bottom: 2rem;
}

.text {
    font-size: 1.6rem;
    font-family: var(--roboto);
    color: var(--darkBlue);
    margin-bottom: 6rem;
    line-height: 2.2rem;
}

.link {
    color: #11a2ed;
    font-weight: 700;
}

.list {
    list-style-type: square;
    margin-left: 2rem;
    margin-bottom: 6rem;
}

@media (max-width: 599px) {
    .title {
        font-size: 2rem;
        margin-bottom: 1rem;
    }

    .text {
        margin-bottom: 3rem;
    }
}
