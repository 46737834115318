.innerContainer {
    padding-top: 5rem;
    padding-bottom: 5rem;
    width: 81rem;
    max-width: 100%;
    margin-right: auto;
    margin-left: auto;
}

.cardContainer {
    padding: 5rem 2rem;
}

.tick {
    width: 4rem;
    height: 4rem;
}

.tickContainer {
    width: 7rem;
    height: 7rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 5px solid var(--green);
    border-radius: 50%;
    margin-left: auto;
    margin-right: auto;
}

.body {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 8rem;
}

.bodyIcon {
    width: 5rem;
    height: 5rem;
    margin-bottom: 2rem;
}

.bodyText {
    font-size: 1.6rem;
    color: var(--darkBlue);
    font-family: var(--roboto);
    width: 70%;
    margin: auto;
    text-align: center;
}

.button {
    margin: 5rem auto;
}

.text {
    font-size: 1.6rem;
    color: var(--darkBlue);
    font-family: var(--roboto);
    margin-bottom: 1rem;
}

.responseTime {
    font-size: 2.4rem;
    color: var(--green);
    font-family: var(--roboto);
    margin-bottom: 2rem;
    font-weight: 700;
}

.link {
    color: var(--green);
    margin-top: 2rem;
    font-weight: 700;
    font-size: 3rem;
    display: inline-block;
}

@media (max-width: 599px) {
    .bodyText {
        width: 100%;
    }

    .cardContainer {
        padding: 0;
    }
}
