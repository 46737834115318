.header {
    font-size: 3.2rem;
    font-family: var(--segoe);
    color: var(--darkBlue);
    text-transform: uppercase;
    margin-bottom: 3rem;
}

.subHeader {
    font-size: 1.6rem;
    font-family: var(--roboto);
    color: var(--darkBlue);
    margin-bottom: 3rem;
}

.inputContainer {
    display: flex;
    align-items: center;
    padding: 1rem 2rem 1rem;
    border: 1px solid #929ea7 !important;
    border-radius: 6px;
    background: #f2fbf9;
    margin-top: 2.9rem;
    height: 54px;
}

.searchIcon {
    width: 20px;
    height: 20px;
    margin-left: 2rem;
}

.input {
    flex: 1;
    font-size: 1.6rem;
    outline: none !important;
    background: transparent;
    border: none;
}

.popularQuestion {
    display: flex;
    width: 100%;
    margin-bottom: 4rem;
}

.icon {
    width: 20px;
    height: 20px;
}

.popularLeft {
    margin-right: 1rem;
}

.popularText {
    font-size: 1.6rem;
    color: var(--darkBlue);
    font-size: 1.6rem;
    font-family: var(--roboto);
    font-weight: 700;
    margin-bottom: 2rem;
}

.popularLink {
    font-size: 1.4rem;
    font-family: var(--segoe);
    font-weight: 700;
    color: #11a2ed;
    text-decoration: none;
}

.popularQuestionsHeaderRow {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    margin-top: 4rem;
    margin-bottom: 6rem;
}

.popularQuestionsHeader {
    font-size: 2rem;
    color: var(--darkBlue);
    font-family: var(--segoe);
    font-weight: 700;
    text-transform: uppercase;
}

.popularQuestionsLink {
    font-size: 1.6rem;
    color: var(--green);
    font-family: var(--segoe);
    font-weight: 700;
    text-decoration: none;
}

.popularQuestionsHeaderRowRight {
    display: flex;
    align-items: center;
}

.hits {
    margin-top: 4rem;
}

.formInputContainer {
    display: flex;
    align-items: center;
    padding: 0;
    border: 1px solid #929ea7 !important;
    border-radius: 6px;
    background: #f2fbf9;
    /* margin-top: 2.9rem; */
}

.formLabel {
    font-size: 1.6rem;
    color: var(--darkBlue);
    font-family: var(--segoe);
    padding-left: 1rem;
    margin-bottom: 1rem;
}

@media (max-width: 599px) {
    .header {
        font-size: 2.6rem;
    }

    .popularQuestionsHeaderRow {
        flex-direction: column;
        align-items: flex-start;
        margin-bottom: 3rem;
    }

    .popularQuestionsHeader {
        margin-bottom: 1rem;
    }

    .popularText {
        margin-bottom: 1rem;
    }

    .popularQuestion {
        margin-bottom: 1rem;
    }
}
