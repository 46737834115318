.Toolbar {
    /* position: sticky; */
    top: 0;
}

.topRow {
    background-color: var(--grey3);
    border-bottom: 1px solid rgba(38, 61, 80, 0.1);
}

.topRowInner {
    display: flex;
    justify-content: flex-end;
    border-collapse: collapse;
    padding: 1rem 0;
}

.menuItem {
    font-size: 1.6rem;
    color: var(--darkBlue);
    font-family: var(--segoe);
    line-height: 2.1rem;
    text-decoration: none;
    padding: 0.5rem 2rem;
    border-left: 1px solid rgba(38, 61, 80, 0.1);
    display: flex;
    align-items: center;
}

.lngicon {
    padding-right: 1rem;
}

.dropdown {
    position: relative;
}

.dropdown > .menuItem {
    border-right: 1px solid rgba(38, 61, 80, 0.1);
}

.dropdown:hover {
    cursor: pointer;
}

.dropdown:hover .dropdownContainer {
    display: block;
}

.dropdownContainer {
    display: none;
    position: absolute;
    z-index: 1000;
    right: 0;
    top: 100%;
    /* top: calc(100% + 1rem); */
    white-space: nowrap;
    background-color: var(--grey3);
    border: 1px solid var(--grey);
}

.dropdownContainer .menuItem {
    border: none;
    padding-top: 1.2rem;
    padding-bottom: 1.2rem;
    border-bottom: 1px solid rgba(38, 61, 80, 0.1);
}

.dropdownContainer .menuItem:hover {
    background-color: var(--grey2);
}

.dropdownContainer .menuItem:last-child {
    border: none;
}

.mainHeader {
    background-color: var(--darkBlue);
    padding: 2rem 0;
    border-bottom: 1px solid rgba(38, 61, 80, 0.1);
}

.mainHeaderInner {
    display: flex;
    justify-content: space-between;
}

.mainHeader.fadeOut {
    background-color: var(--darkBlue);
    transition: background-color 150ms ease-in;
}

.mainHeader.fadeIn {
    background-color: #fff;
    transition: background-color 150ms ease-in;
}

.mainHeader.fadeOut .point span {
    color: #fff;
}

.mainHeader.fadeIn .point span {
    color: var(--darkBlue);
}

.mainPoints {
    padding-left: 1rem;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 3.5rem;
}

.point {
    display: flex;
    align-items: center;
}

.point:hover {
    cursor: pointer;
}

.point .icon {
    width: 3.2rem;
    margin-right: 1rem;
    align-self: center;
    line-height: 24px;
}

.point.hamburgerContainer {
    margin-left: 0;
    margin-right: 2rem;
    border: 1px solid rgba(38, 61, 80, 0.1);
    border-radius: 50%;
    width: 4rem;
    height: 4rem;
    display: flex;
    align-items: center;
    justify-content: center;
}

.point.hamburgerContainer .icon {
    width: 2rem;
    margin: 0;
}

.point span {
    font-size: 1.6rem;
    font-family: var(--segoe);
    line-height: 2.1rem;
    color: #fff;
    white-space: nowrap;
}

.mainPoints .button {
    width: 24rem;
    margin-left: 3rem;
    position: relative;
}

.mainPoints .button.open {
    z-index: 101;
}

.buttonInner {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 54px;
    line-height: 1.5;
}

.buttonInner .icon {
    width: 2.2rem;
    margin-right: 1rem;
    margin-left: 1rem;
}

.yellowBar {
    background-color: var(--yellow);
    border-bottom: 1px solid rgba(38, 61, 80, 0.1);
}

.yellowBarInner {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1.4rem 0;
}

.yellowBar .text {
    font-size: 1.8rem;
    font-family: var(--roboto);
    color: var(--darkBlue);
}

.yellowBar .linkContainer {
    display: flex;
    align-items: center;
}

.yellowBar .linkContainer:hover {
    cursor: pointer;
}

.yellowBar .link {
    font-size: 1.6rem;
    font-family: var(--segoe-bold);
    color: var(--darkBlue);
}

.alwaysShow {
    position: sticky;
    z-index: 100;
    top: -5.6rem;
    left: 0;
}

.alwaysShow.withTop {
    position: sticky;
    z-index: 10;
    top: 0;
    /* top: 5.6rem; */
    left: 0;
}

.hidden {
    display: none !important;
}

.stickyTopRow {
    position: sticky;
    z-index: 100;
    top: 0;
    left: 0;
}

.notificationsOuter {
    margin-left: 2rem;
    position: relative;
}

.notificationsOuter.open {
    z-index: 101;
}

.notificationsOuter :hover {
    cursor: pointer;
}

.notificationsContainer {
    width: 5.4rem;
    height: 5.4rem;
    border-radius: 50%;
    background-color: var(--grey3);
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

.mobileUserButton {
    width: 5.4rem;
    height: 5.4rem;
    border-radius: 50%;
    background-color: var(--green);
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

.mobileUserButton .icon {
    width: 2.2rem;
}

.notificationsOuter.open .notificationsContainer {
    background-color: var(--green);
}

.notificationsContainer .icon {
    width: 2.2rem;
}

.notificationsContainer .counterContainer {
    min-width: 1.6rem;
    width: auto;
    height: 1.6rem;
    padding: 0 2px;
    border-radius: 100px;
    background-color: var(--red);
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    position: absolute;
    bottom: -0.4rem;
    right: -0.4rem;
    font-size: 1.3rem;
    line-height: 1.6rem;
    font-family: var(--segoe-bold);
}

.notificationsOuter .clickDropDownInner .elements {
    padding: 0 0 2rem 0;
}

.notificationsOuter .clickDropDownInner .elements .button {
    margin: 1rem auto 0;
    line-height: 4rem;
}

.notificationsOuter .clickDropDownInner .elements .elementsInner {
    max-height: 35rem;
    overflow-y: auto;
}

.notificationHeader {
    font-size: 2rem;
    font-family: var(--segoe-bold);
    text-transform: uppercase;
    color: var(--darkBlue);
    padding: 2rem 2rem;
}

.NotificationElement {
    width: 38rem;
    padding: 1rem 2rem;
}

.NotificationElement.unread {
    background-color: var(--green3);
}

.NotificationElement.unread .unreadCircle {
    background-color: var(--red);
}

.NotificationElement .unreadCircle {
    width: 0.8rem;
    height: 0.8rem;
    background-color: var(--tranparent);
    border-radius: 50%;
}

.NotificationElement .dateRow {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 0.5rem;
}

.NotificationElement .date {
    font-size: 1.2rem;
    color: var(--grey1);
    font-family: var(--roboto);
    line-height: normal;
}

.NotificationElement .title {
    font-size: 1.4rem;
    font-family: var(--roboto);
    font-weight: 700;
    color: var(--darkBlue);
    margin-bottom: 0.7rem;
    width: 90%;
}

.NotificationElement .text {
    color: var(--grey);
    font-size: 1.2rem;
    font-family: var(--roboto);
    width: 80%;
    line-height: normal;
}

/* Mobil */

.mobileToolbarInner {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 2rem 0;
}

.mobileToolbarInner .hamburgerContainer {
    width: 5.4rem;
    height: 5.4rem;
    border-radius: 50%;
    background-color: var(--grey3);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    margin-left: 2rem;
}

.mobileToolbarInner .hamburgerContainer span {
    position: absolute;
    bottom: 0.5rem;
    font-size: 1.2rem;
    font-family: var(--segoe-bold);
    color: var(--darkBlue);
}

.mobileToolbarInner .hamburgerContainer .icon {
    width: 2rem;
    margin: 0;
}

.mobileButtons {
    display: flex;
}

@media only screen and (max-width: 1200px) {
    .mainPoints .button {
        width: 20rem;
    }

    .point {
        margin-left: 3rem;
    }
}

@media only screen and (max-width: 1150px) {
    .mainPoints .button {
        width: 18rem;
    }

    .point {
        margin-left: 2rem;
    }
}

@media only screen and (max-width: 1070px) {
    .point .icon {
        width: 2.5rem;
        margin-right: 0.6rem;
    }

    .mainPoints .button {
        margin-left: 2rem;
    }

    .notificationsOuter {
        margin-left: 1rem;
    }
}

@media only screen and (max-width: 959px) {
    .mobileToolbar,
    .mainPoints {
        position: relative;
        z-index: 101;
    }

    .mobileToolbar {
        background-color: #fff;
    }

    .mainPoints {
        background-color: var(--grey3);
    }

    .mainPointsInner {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 1rem 0 0.5rem;
        position: relative;
    }

    .point {
        flex-direction: column;
        align-items: center;
        margin: 0;
        width: 33.33%;
        border-left: 1px solid rgba(38, 61, 80, 0.1);
    }

    .point:last-child {
        border-right: 1px solid rgba(38, 61, 80, 0.1);
    }

    .point .icon {
        margin: 0 0 0rem;
        width: 2.6rem;
    }

    .point span {
        font-size: 1.2rem;
        font-family: var(--segoe-bold);
        color: var(--darkBlue);
    }

    .mobileMenuExpanded {
        position: fixed;
        top: 9.4rem;
        z-index: 100;
        background-color: var(--grey3);
        padding: 0 2rem 2rem;
        border-bottom-left-radius: 12px;
        border-bottom-right-radius: 12px;
        max-height: 80vh;
        overflow-y: auto;
    }

    .mobileMenuExpanded .icon {
        width: 2.4rem;
        margin-right: 1.5rem;
    }

    .mobileMenuExpanded .element {
        display: flex;
        align-items: center;
        padding: 1.5rem 0;
        border-bottom: 1px solid rgba(38, 61, 80, 0.1);
    }

    .mobileMenuExpanded .element,
    .accordionInner,
    .accordionMore {
        color: var(--darkBlue);
        font-size: 1.6rem;
        font-family: var(--segoe);
        line-height: normal;
    }

    .mobileMenuExpanded .bold {
        font-family: var(--segoe-bold);
    }

    .accordion {
        padding: 0;
        box-shadow: none !important;
        background-color: transparent !important;
    }

    .accordionSummary {
        padding: 0 !important;
        min-height: auto !important;
        border-bottom: 1px solid rgba(38, 61, 80, 0.1) !important;
    }

    .accordionMore {
        width: 100%;
    }

    .accordionInner {
        align-items: center;
        display: flex;
    }

    .mobileNav {
        position: sticky;
        top: 0;
        left: 0;
        z-index: 102;
    }

    .NotificationElement {
        width: 100%;
        border-bottom: 1px solid rgba(38, 61, 80, 0.1);
    }

    .mobileNav .button {
        width: 100%;
        max-width: 40rem;
        margin: 2rem auto;
        line-height: 4.4rem;
    }

    .NotificationElement.unread {
        background-color: revert;
    }

    .elementsInner {
        max-height: 55vh;
        overflow-y: auto;
    }
}
