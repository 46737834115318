.mainContainer {
    margin-top: 3rem;
    margin-bottom: 3rem;
}

.openFilter {
    font-size: 1.9rem;
    font-family: var(--segoe);
    color: var(--darkBlue);
    line-height: 2.3rem;
    text-transform: uppercase;
}

.filterText {
    font-size: 1.6rem;
    font-family: var(--segoe);
    color: var(--darkBlue);
    line-height: 2rem;
}

.resetFilters {
    cursor: pointer;
    font-size: 1.6rem;
    font-weight: 600;
    text-align: center;
    color: #11a2ed;
    font-family: var(--segoe);
    line-height: 2rem;
}

.expandIcon {
    margin-left: 1rem;
    line-height: 2.3rem;
    font-size: 1.9rem !important;
}

.filterButtonContainer {
    cursor: pointer;
}

.filterSelect {
    margin-top: 1rem !important;
}

.inputContainer {
    display: flex;
    align-items: center;
    padding: 1rem 2rem 1rem;
    border: 1px solid #929ea7 !important;
    border-radius: 6px;
    background: #f2fbf9;
    height: 54px;
    /* margin-top: 2.9rem; */
}

.input {
    flex: 1;
    font-size: 1.6rem;
    outline: none !important;
    background: transparent;
    border: none;
}

.searchIcon {
    width: 20px;
    height: 20px;
    margin-left: 2rem;
}

@media (max-width: 959px) {
    .filterButtonContainer {
        margin-top: 2rem;
    }
}
