.qualifications {
    padding: 0rem 0rem 4rem;
}

.selectBtn {
    /* background-color: #929EA7; */
    width: 100%;
    border-radius: 2.7rem;
    height: 5.4rem;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.selectBtn span {
    /* color: white; */
    font-size: 1.6rem;
    font-family: var(--segoe);
}

.noMatch {
    font-size: 1.9rem;
    font-family: var(--segoe);
    color: var(--darkBlue);
    line-height: 2.3rem;
    text-transform: uppercase;
}

.reservationFilter {
    margin-left: 2rem;
    /* width: 100%; */
    display: flex;
    flex-direction: column;
    font-size: 160%;
}

.reservationFilter .muiSelect * {
    font-size: 100% !important;
}

@media (max-width: 959px) {
    .qualifications {
        padding: 0rem 0rem 2rem;
    }

    .reservationFilter {
        margin: 2rem 0 0 0;
    }
}
