.Hit {
    display: flex;
    margin-bottom: 3rem;
}

.icon {
    width: 3rem;
    height: 3rem;
    margin-right: 3rem;
    margin-top: 1rem;
}

.title {
    font-size: 1.8rem;
    color: var(--darkBlue);
    font-family: var(--robot);
    font-weight: 700;
    margin-bottom: 0.5rem;
}

.text {
    font-size: 1.6rem;
    color: var(--darkBlue);
    font-family: var(--robot);
    margin-bottom: 1rem;
    line-height: 2.2rem;
}

.link {
    color: #11a2ed;
    font-weight: 700;
}
