.selectRoot {
    /* margin-bottom: 3rem; */
}

.selectRoot .inputRoot {
    border-radius: 6px;
    background-color: #f2fbf9;
}

.endAdornment {
    /* top: inherit */
}

.popupIndicator svg {
    width: 2.5rem;
    height: 2.5rem;
}

.inputFocused {
}

.notchedOutline {
    /* border-color: var(--green) !important */
}

.popper {
    font-size: 160%;
}
