.inner {
    padding-top: 5rem;
    padding-bottom: 5rem;
}

.openFilter {
    font-size: 1.9rem;
    font-family: var(--segoe);
    color: var(--darkBlue);
    line-height: 2.3rem;
    text-transform: uppercase;
}

.expandIcon {
    margin-left: 1rem;
    line-height: 2.3rem;
    font-size: 1.9rem !important;
}

.filterButtonContainer {
    /* cursor: pointer; */
    margin-top: 3.1rem;
}

.openMoreContainer {
    cursor: pointer;
    margin-top: 2rem;
}

.searchIcon {
    width: 20px;
    height: 20px;
    margin-right: 1rem;
}

.noMatch {
    font-size: 1.9rem;
    font-family: var(--segoe);
    color: var(--darkBlue);
    line-height: 2.3rem;
    text-transform: uppercase;
}

.link {
    font-family: var(--roboto);
    font-size: 1.6rem;
    font-weight: 700;
    color: #11a2ed;
    line-height: 2.4rem;
    text-decoration: underline;
}

.mainList {
    font-size: 1.6rem;
    font-family: var(--roboto);
    line-height: 2rem;
    color: var(--darkBlue);
    list-style-type: circle;
    margin-left: 4rem;
    margin-bottom: 1rem;
}

.loader {
    width: 100%;
    height: 60rem;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #eee;
}

.loader p {
    margin-left: 4rem;
    font-family: var(--roboto);
    font-size: 1.8rem;
    /* font-weight: 700; */
}

@media (max-width: 959px) {
    .filterButtonContainer {
        margin-top: 1rem;
    }
}
