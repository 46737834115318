.ListingCard {
    display: flex;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
}

.ListingCard:hover {
    cursor: pointer;
}

.imageContainer {
    width: 100%;
    position: relative;
    height: 16rem;
}

.image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    border-radius: 0.6rem;
    border: 1px solid var(--grey2);
}

.contentContainer {
    padding: 1rem 2rem;
}

.availability {
    position: absolute;
    bottom: 1rem;
    left: 1rem;
    background-color: var(--grey);
    color: #fff;
    font-size: 1.4rem;
    /* line-height: 2rem; */
    padding: 0.5rem 1rem;
    font-family: var(--segoe);
    font-weight: 700;
}

.availability.available {
    background-color: #fff;
    color: var(--green);
}

.name {
    font-size: 1.6rem;
    font-family: var(--segoe);
    color: var(--darkBlue);
    line-height: 2.1rem;
    min-height: 5rem;
}

.qualification {
    font-size: 1.4rem;
    font-family: var(--segoe);
    color: var(--grey);
}

.city {
    font-size: 1.4rem;
    color: var(--grey);
    font-family: var(--segoe);
    margin-bottom: 1rem;
}

.serviceRow {
    display: flex;
    align-items: center;
    margin-top: 1rem;
}

.serviceRow span {
    font-size: 1.4rem;
    color: var(--grey);
    font-family: var(--segoe);
}

.acceptPatientIcon {
    width: 1.5rem;
    height: 1.5rem;
    margin-right: 1rem;
}

@media (max-width: 599px) {
    .name {
        font-size: 1.6rem;
        min-height: 6.5rem;
    }

    .imageContainer {
        height: 11rem;
    }

    .contentContainer {
        padding: 1rem 0;
    }
}
