.formRow {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;
    gap: 1.6rem;
    width: 100%;
}

.formLabel {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-family: var(--segoe-semibold);
    color: var(--darkBlue);
    font-size: 1.4rem;
    margin-bottom: 0.5rem;
}

.formLabel svg {
    fill: var(--linkBlue);
}

.button {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 4rem;
    line-height: 4rem;
    background-color: var(--green);
    color: #fff;
    font-family: var(--segoe-semibold);
    margin: unset;
}
