.input {
    font-family: var(--segoe);
    font-size: 1.4rem;
    color: var(--darkBlue);
    width: 100%;
    height: 3.8rem;
    max-width: 100%;
    background-color: #fff;
    border: 1px solid var(--grey2);
    border-radius: 0.4rem;
}

.input > div {
    margin-left: 1rem;
}

.input > input[type="tel"] {
    padding-left: 0.5rem;
    font-family: var(--segoe);
    font-size: 1.4rem;
}

input:disabled {
    color: var(--grey1);
}

.input.bigger {
    height: 5rem;
    border-radius: 6px;
    padding: 1.4rem 1.8rem;
    font-size: 1.6rem;
}
