.title {
    font-family: var(--segoe);
    color: var(--darkBlue);
    font-size: 2rem;
    font-weight: bold;
    margin-top: 2rem;
    margin-bottom: 2rem;
}

.cardText {
    font-size: 1.8rem;
    font-family: var(--roboto);
    font-weight: normal;
    color: #263d50;
    line-height: 2.6rem;
    /* margin-bottom: 2rem; */
}

.cardText span {
    color: #a8b1b9;
}

.icon:hover {
    cursor: pointer;
}

.setDefault {
    font-size: 1.8rem;
    /* margin-left: 2rem; */
    font-family: var(--roboto);
    color: var(--green);
}

.setDefault:hover {
    cursor: pointer;
    text-decoration: underline;
}

.titleContainer {
    display: flex;
    align-items: center;
}

.linkText {
    font-size: 1.8rem;
    font-family: var(--segoe);
    color: #11a2ed;
    font-weight: bold;
    cursor: pointer;
    /* margin-top: 2rem;
  margin-bottom: 2rem; */
}

.paymentInfo {
    font-size: 1.6rem;
    font-family: var(--segoe);
    font-style: italic;
    margin-bottom: 5rem;
    color: #263d50;
}

.label {
    font-size: 1.6rem;
    font-family: var(--segoe);
    margin-bottom: 1rem;
    color: #263d5092;
}

.infoLabel {
    font-size: 1.6rem;
    font-family: var(--segoe);
    margin-bottom: 1rem;
    color: #263d50;
    text-align: center;
}

.cardName {
    margin-bottom: 2rem;
}

.addCardElement {
    margin-top: 2rem;
    padding-top: 2rem;
    border-top: 1px solid #263d5092;
}

.paymentImage {
    height: 5rem;
    margin-right: 3rem;
    margin-bottom: 2rem;
    margin-top: 2rem;
}

@media (max-width: 599px) {
}
