.avatar {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    border-radius: 100rem;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    font-size: 1.4rem;
    color: var(--darkBlue);
    font-family: var(--segoe-semibold);
    background-color: var(--green3);
    width: 4rem;
    height: 4rem;
    border: 1px solid var(--grey4);
    margin-right: 1.6rem;
}

.indicator {
    position: absolute;
    width: 0.5rem;
    height: 0.5rem;
    bottom: 0;
    right: 0;
    background-color: var(--grey2);
}

.active {
    background-color: var(--green);
}
