.inner {
    padding-top: 5rem;
    padding-bottom: 5rem;
}

.pageHeader {
    margin-top: 0;
    padding-top: 5rem;
}

.topContainer {
    background-color: white;
    /* height: 50rem; */
    width: 100%;
    margin-bottom: 4rem;
    margin-top: 4rem;
}

.card {
    padding: 6rem 6rem;
    margin-top: 2rem;
}

.header {
    font-size: 3.2rem;
    font-family: var(--segoe);
    color: var(--darkBlue);
    text-transform: uppercase;
    margin-bottom: 5rem;
}

.title {
    font-family: var(--segoe);
    color: var(--darkBlue);
    font-size: 1.6rem;
    font-weight: bold;
    margin-top: 2rem;
    margin-bottom: 2rem;
}

.icon {
    width: 7rem;
    height: 7rem;
}

.textContainer {
    margin-bottom: 5rem;
}

.innerContainer {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}

.cardText {
    font-size: 1.6rem;
    font-family: var(--roboto);
    font-weight: normal;
    color: #263d50;
    margin-bottom: 1rem;
}

.label {
    font-size: 1.6rem;
    font-family: var(--segoe);
    /* text-transform: uppercase; */
    color: #263d50;
    margin-bottom: 1rem;
    margin-top: 3rem;
}

.input {
    width: 40rem;
    margin-bottom: 3rem;
}

.button {
    width: 40rem;
    line-height: 5rem;
    border-radius: 27rem;
}

.buttonInner {
    text-transform: none;
    align-items: center;
    display: flex;
    width: 100%;
    justify-content: center;
}

@media (max-width: 959px) {
    .card {
        padding: 2rem 2rem;
    }
}

@media (max-width: 599px) {
    .inner {
        padding-top: 2rem;
    }

    .card {
        padding: 0;
    }

    .icon {
        margin-bottom: 1rem;
    }

    .input {
        width: 100%;
    }

    .button {
        width: 100%;
    }

    .innerContainer {
        display: flex;
        flex-direction: column;
        /* justify-content: space-between;
    align-items: flex-start; */
    }

    .textContainer {
        margin-bottom: 2rem;
    }

    .switch {
        width: 100%;
        display: flex;
        justify-content: flex-end;
    }
}
