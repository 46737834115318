.Logo {
    width: 14rem;
}

.homeLogo:hover {
    cursor: pointer;
}

@media (max-width: 959px) {
    .Logo {
        width: 16rem;
    }
}
