.RegistrationEnd {
    padding-top: 5rem;
    height: calc(100vh - 220px);
}

.RegistrationEndInner * {
    color: #263d50;
    font-family: var(--segoe);
}

.RegistrationEnd h3 {
    font-size: 3rem;
    margin-bottom: 4rem;
}

.RegistrationEnd p {
    font-size: 2rem;
    margin-bottom: 1.5rem;
}

.buttonText {
    color: #fff;
}
