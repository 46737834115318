.ServicePage {
    padding: 6rem 10rem;
    /* margin-bottom: 5rem; */
    margin-top: 2rem;
}

.header {
    display: flex;
    justify-content: space-between;
    padding-bottom: 5rem;
    border-bottom: 2px solid #e6e6e6;
}

.image {
    align-self: flex-start;
}

.header > * {
    width: 47.5%;
}

.icon {
    width: 5rem;
}

.name {
    font-size: 3.4rem;
    font-family: var(--segoe);
    text-transform: uppercase;
    color: var(--darkBlue);
    margin-top: 3rem;
    margin-bottom: 2rem;
}

.description {
    font-size: 1.8rem;
    font-family: var(--robot);
    color: var(--darkBlue);
    line-height: 2.4rem;
}

.subscriptionSelectContainer {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-top: 5rem;
    margin-bottom: 5rem;
}

.importantInfoContainer {
    width: 100%;
    background-color: #ffd943;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1rem 15rem;
    margin-bottom: 2rem;
}

.importantInfoText {
    font-size: 1.8rem;
    line-height: 2.4rem;
    font-family: var(--roboto);
    font-weight: 700;
    color: var(--darkBlue);
    margin-bottom: 0;
    margin-left: 1rem;
    margin-top: 1rem;
    margin-bottom: 1rem;
}

.importantInfoIcon {
    width: 9rem;
    /* height: 6rem; */
    margin-right: 1rem;
}

.importantInfoIcon2 {
    width: 5rem;
    /* height: 6rem; */
    margin-right: 1rem;
    margin-left: 1rem;
}

.button {
    justify-content: center;
    /* line-height: 4rem; */
    margin-right: auto;
    margin-left: auto;
    margin-top: 3rem;
    margin-bottom: 5rem;
}

.button.disabled {
    pointer-events: none;
    background-color: #dbdfe2;
    border-color: #dbdfe2;
    color: #263d50;
}

.packageInfoText {
    font-size: 1.4rem;
    font-family: var(--roboto);
    color: var(--darkBlue);
    margin-top: -4rem;
    margin-bottom: 4rem;
}

@media (max-width: 959px) {
    .ServicePage {
        padding: 2rem 2rem;
    }

    .subscriptionSelectContainer {
        justify-content: space-around;
    }
}

@media (max-width: 599px) {
    .ServicePage {
        padding: 0;
    }

    .header {
        flex-direction: column;
    }

    .header > * {
        width: 100%;
    }

    .icon {
        display: none;
    }

    .headerInfoCol {
        order: 0;
    }

    .image {
        order: -1;
        align-self: flex-start;
    }

    .name {
        font-size: 2.4rem;
    }

    .description {
        font-size: 1.6rem;
    }

    .importantInfoIcon {
        display: none;
    }

    .importantInfoIcon2 {
        display: none;
    }

    .importantInfoContainer {
        margin-right: -16px;
        margin-left: -16px;
        width: auto;
        padding: 1.6rem;
    }

    .importantInfoText {
        font-size: 1.4rem;
        margin: 0;
    }
}
