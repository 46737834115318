.intro {
    background-color: var(--grey3);
    padding-top: 3rem;
    padding-bottom: 3.6rem;
}

.PageHeader {
    margin-bottom: 3rem;
}

.subheader {
    font-size: 1.8rem;
    font-family: var(--roboto);
    color: var(--darkBlue);
    line-height: 2.4rem;
    width: 50%;
    text-align: center;
    margin: 0 auto 3rem;
}

.link {
    text-align: center;
}

.connectedServices {
    padding: 5.5rem 0 7rem;
    position: relative;
}

.decoration {
    display: inline-block;
    position: absolute;
    top: -1rem;
}

.decoration span {
    color: var(--darkBlue);
    background-color: var(--yellow);
    font-family: var(--roboto);
    font-size: 1.6rem;
    text-transform: uppercase;
    font-weight: 900;
    padding: 1rem 1.4rem;
    /* position: relative; */
}

.triangle {
    width: 1px;
    height: 1px;
    border: 1.5rem solid transparent;
    border-bottom-color: #e9c944;
    transform: rotateZ(45deg);
    position: absolute;
    bottom: -2.45rem;
    left: 0.62rem;
}

.doctors,
.institutions,
.questions {
    background-color: #fff;
    padding-top: 2rem;
    padding-bottom: 10rem;
}

.popularQuestion {
    display: flex;
    width: 100%;
    margin-bottom: 4rem;
}

.icon {
    width: 20px;
    height: 20px;
}

.popularLeft {
    margin-right: 1rem;
}

.popularText {
    font-size: 1.8rem;
    color: var(--darkBlue);
    font-family: var(--roboto);
    font-weight: 700;
    margin-bottom: 2rem;
    min-height: 5rem;
}

.popularLink {
    font-size: 1.6rem;
    font-family: var(--segoe);
    font-weight: 700;
    color: #11a2ed;
    text-decoration: none;
}

.mainImage {
    width: 100%;
    height: 38rem;
    object-fit: cover;
    border-radius: 12px;
}

.mainImageContainer {
    padding-bottom: 6rem;
    background-color: #fff;
}

.description {
    background-color: #fff;
    padding: 0.1rem 0 6rem;
}

@media (max-width: 959px) {
    .subheader {
        font-size: 1.4rem;
        width: 100%;
    }

    .mainImage {
        height: 14rem;
        /* margin-bottom: 3rem; */
    }

    .mainImageContainer {
        padding-bottom: 3rem;
    }
}
