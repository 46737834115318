* {
    /* outline: 1px solid red !important */
}

.doctorSelect {
    display: flex;
}

.doctorImage {
    width: 13rem;
    height: 8rem;
    object-fit: cover;
    margin-right: 2rem;
}

.doctorName {
    font-size: 2rem;
    color: var(--darkBlue);
    font-family: var(--segoe);
    text-transform: uppercase;
    align-self: flex-start;
}

.qualification {
    font-size: 1.8rem;
    color: #929ea7;
    font-family: var(--segoe);
    align-self: flex-start;
}

.textContainer {
    justify-content: space-around;
    align-items: center;
    flex-direction: column;
}
.fileContainer {
    display: flex;
    flex-wrap: wrap;
    margin-top: 2rem;
}

.uploadButtonInner {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-grow: 1;
}

.uploadIcon {
    width: 2rem;
    height: 2rem;
    margin-right: 1rem;
}

.buttons {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    margin-top: 3rem;
}

.button {
    background-color: #fff;
    border-color: var(--grey);
    /* margin-bottom: 2rem; */
    /* line-height: 5.8rem; */
}

.button:hover {
    background-color: #fff;
}

.buttonText {
    color: var(--darkBlue);
    font-size: 1.6rem;
    font-family: var(--segoe);
}

.alertContainer {
    width: 100%;
    background-color: var(--yellow);
    padding: 2.5rem 10rem;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 3rem;
}

.alertIcon {
    width: 4rem;
    height: 4rem;
    margin-right: 2rem;
}

.alertText {
    font-size: 1.6rem;
    font-family: var(--roboto);
    font-weight: 700;
    color: var(--darkBlue);
}

.link {
    text-decoration: underline;
    color: var(--darkBlue);
}

.link:hover {
    cursor: pointer;
}

.otherInfoText {
    color: var(--darkBlue);
    font-size: 1.8rem;
    font-family: var(--roboto);
    line-height: 2.4rem;
    margin-top: 6rem;
}

.fileContainer {
    width: 100%;
}

.fileContainer .uploadedFile {
    width: 32%;
    margin-right: 0.5%;
    margin-left: 0.5%;
    margin-bottom: 2rem;
}

.uploadInput {
    display: none;
}

.label {
    font-size: 1.6rem;
    color: var(--darkBlue);
    font-family: var(--segoe);
    padding-left: 1rem;
    margin-bottom: 1rem;
}

.subscriptionSelectContainer {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-top: 2rem;
    /* margin-bottom: 2rem; */
}

.bgTextArea {
    height: 20rem;
    width: 100%;
    border: 1px solid #929ea7;
    border-radius: 6px;
    background-color: #f2fbf9;
}

@media (max-width: 1100px) {
    .fileContainer {
        justify-content: space-between;
    }

    .fileContainer .uploadedFile {
        width: 48%;
        margin-right: 0;
        margin-left: 0;
    }
}

@media (max-width: 959px) {
    .button {
        margin-bottom: 2rem;
    }

    .button,
    .sendButton {
        width: 100%;
    }

    .bgTextArea {
        height: 25rem;
    }
}

@media (max-width: 599px) {
    .fileContainer .uploadedFile {
        width: 100%;
    }

    .doctorSelect {
        flex-direction: column;
    }

    .doctorImage {
        width: 7.5rem;
        height: 5rem;
        margin-right: 1rem;
    }

    .row {
        display: flex;
        white-space: nowrap;
    }

    .qualification {
        font-size: 1.4rem;
        white-space: normal;
        margin-top: 1.5rem;
    }

    .alertIcon {
        display: none;
    }

    .alertContainer {
        padding: 1rem 1.6rem;
        margin-left: -16px;
        margin-right: -16px;
        width: auto;
    }

    .alertText {
        font-size: 1.4rem;
    }

    .otherInfoText {
        font-size: 1.6rem;
        margin-top: 3rem;
    }
}
