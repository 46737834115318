.ReactPasswordStrength-input {
    position: absolute;
    top: 0;
    left: 0;
    height: 3.6rem;
    border: none;
    outline: none;
    font-family: var(--segoe);
    color: var(--darkBlue);
    font-size: 1.4rem;
}

.ReactPasswordStrength-strength-bar {
    position: absolute !important;
    top: calc(100%) !important;
    left: 4px !important;
}

.ReactPasswordStrength.is-strength-4 .ReactPasswordStrength-strength-bar {
    width: calc(100% - 8px) !important;
}

.ReactPasswordStrength-strength-desc {
    position: absolute;
    right: 0.6rem;
    bottom: 0;
    transform: translateY(100%);
    width: 25%;
    height: 3rem;
    font-family: var(--segoe-semibold) !important;
    font-style: normal;
    font-size: 1.2rem;
    padding: 0;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    text-transform: unset !important;
}
