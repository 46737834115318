.pageHeader {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 3rem;
    margin-bottom: 3rem;
    position: relative;
}

.line {
    flex-grow: 1;
    height: 1px;
    background-color: var(--darkBlue);
    /* background-color: white; */
}

.closeContainer:hover {
    cursor: pointer;
}

.text {
    font-size: 3.6rem;
    font-family: var(--segoe);
    color: var(--darkBlue);
    text-transform: uppercase;
    margin-left: 3rem;
    margin-right: 3rem;
    line-height: 4.8rem;
    white-space: nowrap;
}

@media (max-width: 599px) {
    /* .pageHeader {
        margin-left: -16px;
        margin-right: -16px;
        margin-top: 2rem;
        margin-bottom: 3rem;
        width: auto;
    } */

    .text {
        margin-left: 2rem;
        margin-right: 2rem;
        font-size: 2.6rem;
        max-width: 80%;
        flex: 1;
        text-align: center;
        line-height: normal;
        white-space: normal;
    }
}
