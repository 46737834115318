.loaderContainer {
    height: 30rem;
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.loaderContainer > p {
    margin-top: 2rem;
}
