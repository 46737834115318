.container {
    display: flex;
    align-items: center;
}

.text {
    font-size: 1.6rem;
    font-family: var(--roboto);
    font-weight: normal;
    color: #263d50;
    margin-right: 1rem;
}
