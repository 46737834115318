.checkboxWrapper {
    position: relative;
    padding: 0.4rem 1.2rem;
    width: 100%;
    min-height: 3.8rem;
    height: auto;
}

.checkboxWrapper.raw {
    padding: 0;
}

.checkboxWrapper.raw .checkboxLabel {
    border: unset;
    padding-left: 2.5rem;
}

.checkboxWrapper.raw input[type="checkbox"]:checked + .checkboxLabel {
    border: unset;
}

.checkboxLabel {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    font-family: var(--segoe);
    color: var(--darkBlue);
    font-size: 1.4rem;
    display: flex;
    align-items: center;
    padding-left: 3.5rem;
    border-radius: 0.4rem;
    border: 1px solid var(--grey2);
}

.checkboxLabel:hover,
.checkboxWrapper input:hover {
    cursor: pointer;
}

.checkboxLabel span {
    line-height: 1;
}

.checkboxWrapper input {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    margin: unset;
    margin-right: 1rem;
    flex: 0;
    width: 1.6rem;
    height: 1.6rem;
    z-index: 1;
    border: 1px solid var(--grey2);
}

.checkboxWrapper input[type="checkbox"]:checked + .checkboxLabel {
    border: 1px solid var(--darkBlue);
}
