.products {
    padding: 2.5rem 4rem;
}

@media (max-width: 599px) {
    .products {
        padding: 1.8rem 1.6rem;
    }
}

@media (max-width: 768px) {
}

@media (max-width: 959px) {
}
