.headline {
    padding: 2.5rem 4rem;
    border-bottom: 1px solid var(--grey2);
}

@media (max-width: 599px) {
    .headline {
        padding: 1.8rem 1.6rem;
    }
}
