.pageHeader {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 3rem;
    padding-top: 5rem;
    position: relative;
}

.line {
    flex-grow: 1;
    height: 1px;
    background-color: #c2c8cd;
}

.text {
    font-size: 3.6rem;
    font-family: var(--segoe);
    color: var(--darkBlue);
    text-transform: uppercase;
    margin-left: 3rem;
    margin-right: 3rem;
    line-height: 4.8rem;
    white-space: nowrap;
}

@media (max-width: 750px) {
    .text {
        margin-left: 0;
        margin-right: 0;
        font-size: 26px;
        min-width: 76%;
        flex: 1;
        text-align: center;
        line-height: normal;
        white-space: normal;
    }

    h6 {
        font-size: 14px;
    }
}
