.intro {
    background-color: var(--grey3);
    padding-top: 3rem;
    padding-bottom: 3.6rem;
}

.PaperSection {
    padding: 4rem;
}

.PageSubHeader {
    margin-bottom: 2rem;
}

.grey {
    background-color: #e6e6e6;
    width: 100%;
    height: 52rem;
    margin-top: 7rem;
    margin-bottom: 5rem;
}

.yellowButton {
    background: var(--yellow);
    border-color: transparent;
    color: var(--darkBlue);
}

.yellowButton:hover {
    background: #d7b62f;
}

.text {
    font-size: 1.6rem;
    font-family: var(--segoe);
    text-align: right;
    margin-top: 3rem;
    padding-bottom: 2rem;
}

.deleteLink {
    font-weight: 700;
    color: #11a2ed;
    margin-left: 1rem;
    cursor: pointer;
}

.btnDeleteProfile {
    border: 1px solid var(--amaranth);
    border-radius: 10rem;
    background-color: #fff;
    padding: 0.5rem 1rem;
    color: var(--amaranth);
    font-family: var(--segoe-semibold);
    font-size: 1.2rem;
    cursor: pointer;
}

.btnDeleteProfile:hover {
    background-color: var(--amaranth);
    color: #fff;
}

@media (max-width: 599px) {
    .text {
        text-align: center;
    }

    .pageHeader {
        padding-top: 0;
    }

    .topTitleWrapper p {
        font-size: 2.2rem;
    }

    .topTitleWrapper span {
        font-size: 1.4rem;
    }
}
