.info {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 1.6rem;
}

.icon {
    position: relative;
    background: var(--green1);
    border-radius: 16px;
    padding: 20px;
    width: 7.2rem;
    height: 7.2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--green);
    font-size: 10rem;
}

.productCounter {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: -0.5rem;
    left: -0.5rem;
    z-index: 1;
    line-height: 2rem;
    color: #fff;
    background-color: var(--orange);
    border-radius: 10rem;
    padding: 0 0.7rem;
    min-width: 2.4rem;
    height: 2.4rem;
    font-size: 1.6rem;
    font-family: var(--segoe);
}

.headline {
    color: var(--darkBlue);
    font-size: 1.8rem;
    font-family: var(--segoe-bold);
    line-height: 2.6rem;
}

.text {
    color: var(--grey);
    font-size: 1.4rem;
    font-family: var(--segoe-semibold);
    line-height: 2rem;
    letter-spacing: 0.07px;
}

.link {
    color: var(--green);
    font-size: 1.4rem;
    font-family: var(--segoe-semibold);
    line-height: 2rem;
    letter-spacing: 0.07px;
    text-decoration: none;
    cursor: pointer;
}
