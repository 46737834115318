.PaymentModalInner {
    width: 70rem;
    padding: 3rem 0rem;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.infoText {
    font-size: 1.6rem;
    font-family: var(--roboto);
    color: var(--darkBlue);
    font-weight: 700;
    text-align: center;
    width: 80%;
    margin-bottom: 3rem;
    /* line-height: normal; */
}

.box {
    width: 24rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1.2rem 2rem;
    background: #f8f8f8;
    border-radius: 6px;
    border: 2px solid var(--green);
    margin-bottom: 6rem;
}

.boxTitle {
    font-size: 1.6rem;
    font-family: var(--roboto);
    color: var(--darkBlue);
    margin-bottom: 1rem;
    text-align: center;
}

.price {
    font-size: 2.6rem;
    font-weight: 700;
    font-family: var(--roboto);
    color: var(--darkBlue);
}

.tick {
    width: 3rem;
    height: 3rem;
    margin-bottom: 1rem;
}

.link {
    font-family: var(--segoe-semibold);
    color: #11a2ed;
    font-weight: 700;
    text-decoration: none;
}

.line {
    width: 100%;
    background-color: #929ea7;
    height: 1px;
}

.checkboxes {
    padding: 2rem 5rem;
}

.couponRow {
    display: flex;
    align-items: flex-end;
    margin-top: -3rem;
    margin-bottom: 3rem;
}

.couponInputLabel {
    color: var(--grey);
    font-family: var(--robot);
    font-size: 1.6rem;
    margin-bottom: 1rem;
}

.inputContainer {
    margin-right: 2rem;
}

.couponButton {
    line-height: 3.8rem;
    width: 17rem;
    margin-left: 2rem;
}

@media (max-width: 959px) {
    .PaymentModalInner {
        padding: 0rem;
        width: 40rem;
    }
}

@media (max-width: 550px) {
    .PaymentModalInner {
        width: auto;
    }

    .checkboxes {
        padding: 1rem 0rem;
    }

    .infoText {
        width: 100%;
        margin-top: -1rem;
    }
}
