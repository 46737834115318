.hero {
    background-image: url("./assets/HeroBackgroundImage.jpg");
    background-position: right;
    background-size: cover;
    background-position: right;
    background-repeat: no-repeat;
}

.heroBox {
    width: 100%;
    position: relative;
    border-radius: 1.2rem;
    display: flex;
    justify-content: space-between;
}

.heroBoxElement {
    background-color: rgba(255, 255, 255, 0.9);
    border-radius: 1.2rem;
    padding: 2rem 1rem;
    width: 28%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.heroBox .icon {
    width: 6rem;
}

.button {
    height: 54px;
    width: 338px;
    max-width: 100%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 50px;
}

@media (max-width: 959px) {
    .hero {
        background-image: none;
    }
}
