:root {
    font-size: 62.5%;
    --green: #1fba9c;
    --green1: #b8e9e0;
    --green2: #ccebe5;
    --green3: #f2fbf9;
    --green4: #f1faf9;
    --green5: #d7f0eb;
    --lightGreen: #a4f2e3;
    --darkBlue: #263d50;
    --yellow: #ffd943;
    --deepLemon: #ffc319;
    --deepLemon1: #ffdb75;
    --deepLemon2: #fff3d1;
    --romanSilver: #7e9591;
    --cloud: #f8f8f8;
    --mercury: #919da6;
    --grey: #929ea7;
    --grey1: #b5b5b5;
    --grey2: #dadee1;
    --grey3: #f8f8f8;
    --grey4: #dededf;
    --grey5: #efefef;
    --white: #fff;
    --linkBlue: #11a2ed;
    --amaranth: #ed2d46;
    --amaranth1: #f8abb5;
    --amaranth2: #fbd5da;
    --red: #ed1124;
    --orange: #ff6e43;
    --humedDark: #10487e;
    --humedLight: #d0f0ff;
    --segoe: "Segoe", Tahoma, Verdana, sans-serif;
    --segoe-semibold: "SegoeSemibold", Tahoma, Verdana, sans-serif;
    --segoe-bold: "SegoeBold", Tahoma, Verdana, sans-serif;
    --segoe-italic: "SegoeItalic", Tahoma, Verdana, sans-serif;
    --segoe-light: "SegoeLight", Tahoma, Verdana, sans-serif;
    --roboto: "Roboto", sans-serif;
}

* {
    line-height: normal;
}

* {
    box-sizing: border-box;
    /* outline: 1px solid red; */
}

html {
    /* scroll-behavior: smooth; */
}

/* FORM VALIDATION */
.validationError,
.validationError span {
    color: var(--red) !important;
    border-color: var(--red) !important;
}
.validationError input {
    color: var(--red) !important;
}

.customDatePicker .MuiFormControl-root {
    font-size: 170%;
    width: 100%;
}

.usersettings .MuiFormControl-root *,
.customDatePicker .MuiFormControl-root * {
    font-size: 100% !important;
}

.MuiPickersModal-dialog .MuiIconButton-label p {
    font-size: 1.4rem;
}

.MuiPickersModal-dialog .MuiPickersCalendarHeader-dayLabel {
    font-size: 1.4rem;
}

.MuiPickersModal-dialog .MuiPickersCalendarHeader-transitionContainer p {
    font-size: 1.6rem;
}

.MuiPickersModal-dialog
    .MuiPickersToolbarButton-toolbarBtn
    .MuiTypography-subtitle1 {
    font-size: 1.6rem;
}

.MuiPickersModal-dialog .MuiPickersYear-root {
    font-size: 1.6rem;
}

.MuiPickersModal-dialogRoot .MuiDialogActions-root .MuiButton-root {
    font-size: 1.2rem;
}

/* EDITOR */

.editor-container * {
    all: revert;
    color: var(--darkBlue);
}

.editor-container h1 {
    font-size: 3.2rem;
    font-family: var(--segoe);
    /* color: var(--green); */
}

.editor-container h2 {
    font-size: 2.4rem;
    font-family: var(--segoe);
    /* color: var(--green); */
}

.editor-container h3 {
    font-size: 20.8rem;
    font-family: var(--segoe);
    /* color: var(--green); */
}

.editor-container h4 {
    font-size: 1.6rem;
    font-family: var(--segoe);
    /* color: var(--green); */
}

.editor-container h5 {
    font-size: 12.8rem;
    font-family: var(--segoe);
    /* color: var(--green); */
}

.editor-container h6 {
    font-size: 11.2rem;
    font-family: var(--segoe);
    /* color: var(--green); */
}

.editor-container p {
    font-size: 1.6rem;
    font-family: var(--roboto);
}

.editor-container li {
    font-size: 1.6rem;
    font-family: var(--roboto);
}

.document-edited p {
    font-size: 1.6rem;
    font-family: garamond;
}

.document-edited li {
    font-size: 1.6rem;
    font-family: garamond;
    /* font-family: var(--roboto); */
}

.document-edited li::marker {
    font-size: 1.6rem;
    font-family: garamond;
}

.document-edited ul li::marker {
    content: "- ";
}

.document-edited td {
    border: 1px solid var(--darkBlue);
    padding: 0.5rem;
    font-size: 1.6rem;
    font-family: garamond;
}

/* .document td p {
  margin: 0;
} */

.document-edited td p span {
    font-size: 1.6rem !important;
    font-family: garamond !important;
}

.document-edited tbody {
    font-size: 1.6rem;
}

.document-edited a {
    color: var(--linkBlue);
}

.document-edited a span {
    color: var(--linkBlue);
}

.link {
    font-size: 1.6rem;
    font-family: var(--segoe-bold);
    color: var(--linkBlue);
}

.link:hover {
    cursor: pointer;
}
