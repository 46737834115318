.inner {
    padding-top: 5rem;
    padding-bottom: 5rem;
}

.card {
    padding: 6rem 10rem;
    margin-top: 2rem;
}

.header {
    display: flex;
    justify-content: space-between;
    padding-bottom: 5rem;
    border-bottom: 2px solid #e6e6e6;
}

.headerInfoCol {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.header > * {
    width: 47.5%;
}

.image {
    height: 32rem;
    object-fit: cover;
    object-position: center;
}

.icon {
    width: 5rem;
}

.name {
    font-size: 3.4rem;
    font-family: var(--segoe);
    text-transform: uppercase;
    color: var(--darkBlue);
    margin-top: 3rem;
    margin-bottom: 2rem;
}

.description {
    font-size: 1.8rem;
    font-family: var(--robot);
    color: var(--darkBlue);
    line-height: 2.4rem;
}

.languages {
    font-size: 1.6rem;
    font-family: var(--robot);
    color: #7e9591;
    line-height: 2.2rem;
    margin-bottom: 1rem;
    margin-top: 1rem;
}

.button.disabled {
    pointer-events: none;
    background-color: #dbdfe2;
    border-color: #dbdfe2;
    color: #263d50;
}

.buttonContainerInner {
    display: flex;
    justify-content: space-between;
    margin-top: 2rem;
    flex-direction: column;
}

.button {
    margin: 1rem 0 0 0;
}

.chatButton {
    background-color: #fff;
    border-color: var(--darkBlue);
    color: var(--darkBlue);
    margin: 1rem 0 0 0;
}

.chatButton:hover {
    background-color: var(--green);
    color: #fff;
}

/* .introductionHeader {
    font-size: 1.8rem;
    color: var(--green);
    font-family: var(--segoe);
    font-weight: 700;
    margin-top: 5rem;
    margin-bottom: 2rem;
    text-transform: uppercase;
}

.introduction {
    font-size: 1.6rem;
    color: var(--darkBlue);
    font-family: var(--roboto);
    margin-bottom: 2rem;
} */

@media (max-width: 959px) {
    .card {
        padding: 2rem 2rem;
    }

    .buttonContainerInner {
        margin-top: 0;
        flex-direction: column;
    }

    .button {
        width: 100%;
    }
}

@media (max-width: 599px) {
    .card {
        padding: 0;
    }

    .header {
        flex-direction: column;
    }

    .header > * {
        width: 100%;
    }

    .icon {
        display: none;
    }

    .headerInfoCol {
        order: 0;
    }

    .image {
        order: -1;
        height: 20rem;
    }

    .name {
        font-size: 2.4rem;
    }

    .description {
        font-size: 1.6rem;
    }

    .inner {
        padding-top: 2rem;
    }

    .button {
        width: 100%;
    }
}
