.modalBody {
    padding: 0 !important;
}

.inner {
    padding: 0 10rem;
}

.inputContainer {
    display: flex;
    align-items: center;
    padding: 1rem 2rem 1rem;
    border: 1px solid #929ea7 !important;
    border-radius: 6px;
    background: #f2fbf9;
    margin-bottom: 3rem;
}

.searchIcon {
    width: 20px;
    height: 20px;
    margin-left: 2rem;
}

.input {
    flex: 1;
    font-size: 1.6rem;
    outline: none !important;
    background: transparent;
    border: none;
}

.pageHeader {
    margin-bottom: 3rem;
}

.line {
    /* margin-bottom: 6rem; */
    /* background-color: var(--darkBlue); */
}

.qualifications {
    padding: 0rem 10rem 4rem;
}

.skeletons {
    column-count: 4;
    padding-top: 3rem;
}

.skeleton {
    display: inline-block !important;
    width: 100%;
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
}

@media (max-width: 959px) {
    .qualifications {
        padding: 0rem 2rem 2rem;
    }

    .inner {
        padding: 0 2rem;
    }
}
