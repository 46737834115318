.section {
    padding: 8rem 4rem;
    border-bottom: 1px solid var(--grey2);
}

.section.inverted {
    background-color: var(--grey4);
}

.section .editor-container * {
    all: revert;
    color: var(--darkBlue);
}

.section .editor-container h1 {
    font-size: 3.2rem;
    font-family: var(--segoe);
    /* color: var(--green); */
}

.section .editor-container h2 {
    font-size: 2.4rem;
    font-family: var(--segoe);
    /* color: var(--green); */
}

.section .editor-container h3 {
    font-size: 20.8rem;
    font-family: var(--segoe);
    /* color: var(--green); */
}

.section .editor-container h4 {
    font-size: 1.6rem;
    font-family: var(--segoe);
    /* color: var(--green); */
}

.section .editor-container h5 {
    font-size: 12.8rem;
    font-family: var(--segoe);
    /* color: var(--green); */
}

.section .editor-container h6 {
    font-size: 11.2rem;
    font-family: var(--segoe);
    /* color: var(--green); */
}

.section .editor-container p {
    font-size: 1.6rem;
    font-family: var(--roboto);
}

.section .editor-container li {
    font-size: 1.6rem;
    font-family: var(--roboto);
}

@media (max-width: 599px) {
    .section {
        padding: 4rem 1.6rem !important;
        border-bottom: 1px solid var(--grey2);
    }
}
