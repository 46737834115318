.sidebar {
    position: sticky;
    top: 11rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    max-height: 84.5rem;
}

.menu {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem;
    padding: 2.5rem 0;
    width: 100%;
}

.menu li {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 2rem;
    border-left: 0.5rem solid transparent;
    padding: 1.4rem 2.5rem 1.4rem 1.5rem;
    width: 100%;
    color: var(--darkBlue);
    font-family: var(--segoe);
    font-size: 1.6rem;
    text-decoration: none;
    transition: all 0.1s ease-in-out;
}

.menu li:hover,
.menu li.active {
    font-family: var(--segoe-semibold);
    background-color: var(--green4);
    border-left: 5px solid var(--green);
    color: var(--green);
    cursor: pointer;
}

.menu li:hover .badge,
.menu li.active .badge {
    color: #fff;
    background-color: var(--orange);
    font-family: var(--segoe-semibold);
    font-size: 1.4rem;
    transition: all 0.1s ease-in-out;
}

.menu li.placeholder {
    display: none;
}

.badge {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: auto;
    border-radius: 10rem;
    background-color: var(--grey3);
    min-width: 3.2rem;
    height: 2rem;
    transition: all 0.1s ease-in-out;
}

.button {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.4rem;
    height: 4rem;
    line-height: 4rem;
    background-color: var(--green);
    color: #fff;
    font-family: var(--segoe-semibold);
    width: 20rem;
    height: 4rem;
    margin: 0 auto 4rem;
}

.mobile-menu {
    display: none;
}

@media (max-width: 959px) {
    .sidebar {
        all: unset;
        border-radius: 0.6rem;
        top: 0;
        margin-bottom: 2rem;
        background-color: var(--green2);
        padding: 2.5rem;
        overflow: unset !important;
        position: relative;
    }

    .menu {
        all: unset;
        position: relative;
        padding: 0;
        gap: 0;
        background-color: var(--white);
        z-index: 99;
        border-radius: 0.8rem;
        display: flex;
        flex-direction: column;
        border: 1px solid var(--grey2);
    }

    .menu li {
        all: unset;
        display: none;
        gap: 1rem;
        align-items: center;
        background-color: var(--white);
        padding: 0.8rem 1rem;
        border-left: 5px solid transparent;
    }

    .menu li:first-of-type {
        padding-top: 1rem;
    }

    .menu li:last-of-type {
        padding-bottom: 1rem;
    }

    .menu li.placeholder {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .menu li.placeholder span {
        margin-right: auto;
    }

    .button {
        display: none;
    }

    .mobile-menu {
        display: flex;
    }
}
