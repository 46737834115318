.arrow {
    color: var(--darkBlue) !important;
}

.tooltip {
    background-color: var(--darkBlue) !important;
    font-size: 1.2rem !important;
    margin: 0.5rem 0 !important;
    padding: 0.8rem 1.2rem !important;
    max-width: 36rem;
}
