.yellowBar {
    background-color: var(--yellow);
    border-top: 1px solid #d0a018;
    border-bottom: 1px solid #d0a018;
}
.yellowBar:hover {
    cursor: pointer;
}

.yellowBarInner {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem 0;
}

.yellowBar .text {
    font-size: 1.6rem;
    font-family: var(--roboto);
    color: var(--darkBlue);
    text-align: center;
}

.yellowBar .linkContainer {
    display: flex;
    align-items: center;
}

.yellowBar .linkContainer:hover {
    cursor: pointer;
}

.yellowBar .link {
    font-size: 1.6rem;
    font-family: var(--segoe-bold);
    color: var(--darkBlue);
}

.yellowBar .chevron {
    width: 2.5rem;
    height: 2.5rem;
}
