.service {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    background-color: var(--green4);
    border: 1px solid var(--green1);
    border-radius: 0.6rem;
    padding: 2rem;
    min-height: 22rem;
}

.service header {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 2rem;
}

.service .icon {
    position: relative;
    top: 3px;
    margin-right: 1.4rem;
}

.service .icon svg {
    fill: var(--green);
}

.service h3 {
    font-family: var(--segoe-semibold);
    font-size: 1.8rem;
    line-height: 2.4rem;
    color: var(--darkBlue);
    text-transform: unset;
}

.service p {
    font-size: 1.4rem;
    font-family: var(--segoe);
    line-height: 1.9rem;
    color: var(--darkBlue);
}

.service footer {
    display: flex;
    justify-content: flex-end;
    align-items: baseline;
    margin-top: auto;
}

.service footer .price {
    font-size: 1.4rem;
    margin-right: auto;
    line-height: 1;
    padding: 0.4rem 0;
    color: var(--darkBlue);
}

.service footer .price.highlighted {
    background-color: var(--yellow);
    border-radius: 0.3rem;
    padding: 0.4rem 1.2rem;
    font-family: var(--segoe-semibold);
}

.service footer .link {
    font-size: 1.5rem;
    font-family: var(--segoe-semibold);
    color: var(--linkBlue);
    line-height: 1;
}

.service footer .link:disabled {
    pointer-events: none;
    cursor: default;
    color: var(--grey1);
    text-decoration: unset;
}

@media (max-width: 599px) {
    .service {
        min-height: 20rem;
        padding: 1.8rem;
    }
}
