.sliderContainer {
    position: relative;
}

.controller {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    margin-bottom: 3rem;
}

.pull-up {
    margin-top: -6rem; /* Bottom margin plus height of Section's PageHeader */
}

.navigation {
    width: 2.8rem;
    height: 2.8rem;
    border-radius: 0.3rem;
    border: 1px solid var(--linkBlue);
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
}

.navigation:last-child {
    margin-left: 1rem;
}

.navigation:hover {
    cursor: pointer;
}

.icon {
    width: 2rem !important;
    height: 2rem !important;
    fill: var(--linkBlue) !important;
}

@media (max-width: 599px) {
    .pull-up {
        margin-top: -4.1rem;
    }
    .controller {
        margin-bottom: 1.9rem;
    }
}
