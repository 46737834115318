.serviceCard {
    width: 100%;
    background-color: #fff;
    border-radius: 1.2rem;
    box-shadow: 0px 3px 10px #0000000d;
    transition: all 0.1s ease;
}

.serviceCard:hover {
    cursor: pointer;
    transform: scale(1.01);
}

.vertical {
    display: flex;
    padding: 3rem;
}

.titleContainer {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
}

.icon {
    width: 6rem;
}

.vertical .icon {
    margin-right: 3rem;
}

.title {
    font-size: 1.8rem;
    font-family: var(--segoe-bold);
    color: var(--darkBlue);
    text-transform: uppercase;
}

.vertical .title {
    margin-right: 1.4rem;
}

.text {
    font-size: 1.6rem;
    color: var(--darkBlue);
    font-family: var(--roboto);
    font-weight: 300;
    line-height: normal;
}

@media screen and (max-width: 350px) {
    .vertical {
        display: flex;
        padding-top: 3rem;
        padding-bottom: 3rem;
        padding-right: 0.5rem;
        padding-left: 0.5rem;
    }

    .vertical .icon {
        margin-right: 0.2rem;
    }

    .vertical .title {
        margin-right: 0.2rem;
    }

    .serviceCard {
        overflow: hidden;
    }
}
