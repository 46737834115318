.container {
    width: 100%;
    background-color: var(--green3);
    border: 1px solid var(--grey);
    border-radius: 6px;
    padding: 2rem 1rem;
}

.textarea {
    resize: none;
    width: 100%;
    background-color: transparent;
    border: none;
    outline: none;
    font-size: 1.8rem;
    color: var(--darkBlue);
    font-family: var(--segoe);
    height: fit-content;
    padding-top: 0.2rem;
}

.label {
    font-size: 1.6rem;
    color: var(--darkBlue);
    font-family: var(--segoe);
    padding-left: 1rem;
    margin-bottom: 1rem;
}

@media (max-width: 599px) {
    .textarea {
        font-size: 1.6rem;
    }
}
