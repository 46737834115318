.ForgotPassword {
    height: calc(100vh - 306px);
}

.ForgotPasswordInner {
    padding-top: 5rem;
}

.ForgotPasswordInner * {
    color: #263d50;
    font-family: var(--segoe);
}

.ForgotPassword h3 {
    font-size: 3rem;
    margin-bottom: 4rem;
}

.ForgotPassword p {
    font-size: 2rem;
    margin-bottom: 1.5rem;
}
