.slick-slider {
    margin-left: -0.75rem;
    margin-right: -0.75rem;
}

.slick-slide {
    padding: 0 0.75rem;
}

.slick-list > div {
    margin-left: 0;
}
